import { useEffect, useRef } from 'react';

import useCustomRouter from './useCustomRouter';

const usePreviousRoute = () => {
	const { asPath } = useCustomRouter();

	const ref = useRef<string | null>(null);

	useEffect(() => {
		ref.current = asPath;
	}, [asPath]);

	return ref.current;
};

export default usePreviousRoute;
