import { Icon } from '@elipssolution/harfang';
import { mdiFileDocument } from '@mdi/js';

import documentPages from './pages/pages';
import DocumentSettings from './settings/DocumentSettings';
import widgets from './widgets/widgets';
import { ModuleType } from '../../types/module';
import { PermissionEnum } from '../../types/permission';
import { ModuleCodeEnum } from '../../types/widget';

const documentModule: ModuleType = {
	code: ModuleCodeEnum.DOCUMENT,
	icon: <Icon path={mdiFileDocument} />,
	name: 'Document',
	permissionCodes: [PermissionEnum.DOCUMENT_DOWNLOAD, PermissionEnum.DOCUMENT_UPLOAD],
	route: `/${ModuleCodeEnum.DOCUMENT}`,
	settings: <DocumentSettings />,
	subItems: documentPages,
	widgets,
};

export default documentModule;
