import {
	Button,
	FilterMenuOutputType,
	GenericFiltersType,
	Table,
	TableColumnType,
	TableInstance,
	TableOrderByType,
} from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { styled } from '@mui/material';

const Wrapper = styled('section')({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

const Footer = styled('footer')({
	display: 'flex',
	justifyContent: 'flex-end',
});

type SettingsTableProps<T, F extends GenericFiltersType = Record<string, never>> = {
	addButtonLabel?: string | null;
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<T>,
		filters?: FilterMenuOutputType<F>,
	) => Promise<{ items: T[]; count: number }>;
	height?: number;
	minHeight?: number;
	onAddButtonClick?: () => void;
	onRowClick?: (item: T) => void;
	table?: TableInstance;
	tableColumns: TableColumnType<T>[];
	title: string;
	filters?: F;
	enableSearch?: boolean;
};

const SettingsTable = <T, F extends GenericFiltersType = Record<string, never>>({
	addButtonLabel = 'Ajouter',
	dataSource,
	height,
	minHeight = 400,
	onAddButtonClick,
	onRowClick,
	table,
	tableColumns,
	title,
	filters,
	enableSearch = true,
}: SettingsTableProps<T, F>) => {
	const handleAddButtonClick = () => {
		onAddButtonClick?.();
	};

	return (
		<Wrapper>
			<Table<T, F>
				columns={tableColumns}
				dataSource={dataSource}
				onRowClick={onRowClick}
				style={{
					height,
					minHeight,
					flex: minHeight && 1,
				}}
				filters={filters}
				table={table}
				title={title}
				enableSearch={enableSearch}
			/>

			{addButtonLabel !== null && onAddButtonClick && (
				<Footer>
					<Button onClick={handleAddButtonClick} startIcon={<Icon path={mdiPlus} size="24px" />} variant="contained">
						{addButtonLabel}
					</Button>
				</Footer>
			)}
		</Wrapper>
	);
};

export default SettingsTable;
