import { SignContactType } from './contact';
import { SignerType } from './signer';

export enum DocumentFieldTypeEnum {
	SIGNATURE = 'SIGNATURE',
	MENTION = 'MENTION',
	READ_ONLY_TEXT = 'READ_ONLY_TEXT',
}

export type DocumentFieldsType = {
	texts?: (DocumentFieldPropertiesType & { content: string })[];
	signatures: (DocumentFieldPropertiesType & { signatoryId: SignContactType['id'] })[];
	mentions?: (DocumentFieldPropertiesType & { signatoryId: SignContactType['id']; mention: string })[];
};
export type DocumentFieldPropertiesType = {
	page: number;
	x: number;
	y: number;
	width: number;
	height: number;
	documentId: string;
};

export type DocumentFieldType = Omit<DocumentFieldPropertiesType, 'documentId' | 'x' | 'y'> & {
	id?: string;
	type: DocumentFieldTypeEnum;
	signerId?: SignContactType['id'];
	text?: string;
	x: number;
	y: number;
	mention?: string;
	hasError?: boolean;
};

export type SignDocumentType = {
	document?: File;
	id: string;
	fields?: DocumentFieldType[];
	filename?: string;
	afterId?: string;
	hasOrderChanged?: boolean;
};

export type ProcedureDocumentType = {
	id: string;
	filename: string;
	fields: (Pick<DocumentFieldType, 'type' | 'id' | 'page' | 'x' | 'y' | 'width' | 'height' | 'text' | 'mention'> & {
		signer: { id: string; contact: SignContactType };
	})[];
	signers: SignerType[];
};

export type DocumentMapperType = {
	id: SignDocumentType['id'];
	yousignId?: SignDocumentType['id'];
	createdFieldsMapper?: DocumentFieldType['id'][];
};

export type yousignDocument = {
	documentYousignId: DocumentMapperType['yousignId'];
	fieldsYousignIds: DocumentMapperType['createdFieldsMapper'];
};

export type FieldError = {
	index: number;
	reason: 'SIGNER_INVALID' | 'FIELD_INVALID';
};

export type YousignServiceUnavailable = { isYousignServiceUnavailable?: boolean };

type PartialDocumentData = {
	documentYousignId: SignDocumentType['id'];
	fieldsYousignIds: string[];
	fieldErrors?: FieldError[];
};

export type DocumentsErrors = YousignServiceUnavailable & {
	interruptedDocumentId?: SignDocumentType['id'];
	partialData?: PartialDocumentData[];
};

export type UploadDocumentError = YousignServiceUnavailable & {
	partialData?: PartialDocumentData;
};
