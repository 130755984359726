import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { Sidebar, useAutocomplete } from '@elipssolution/harfang';
import { mdiDomain } from '@mdi/js';
import Icon from '@mdi/react';
import getConfig from 'next/config';
import { useCallback, useEffect, useState } from 'react';

import { useSession } from './SessionProvider';
import { CustomerFileType } from '../../types/customerFile';
import {
	FETCH_CUSTOMER_FILES,
	FetchCustomerFilesType,
	FETCH_CUSTOMER_FILES_BY_MODULE_CODE,
	FetchCustomerFilesByModuleCodeType,
} from '../api/customerFile';
import usePreviousRoute from '../hooks/usePreviousRoute';
import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';

const SidebarCustomerFileSelector = () => {
	const prevPath = usePreviousRoute();
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { standaloneModule } = publicRuntimeConfig;

	const {
		changeCustomerFile: changeSessionCustomerFile,
		customerFile: sessionCustomerFile,
		refetch: refetchSession,
	} = useSession();

	// This state is volontary redundant with the customerFile of session because we need to display the selected user before the session refetch.
	const [customerFile, setCustomerFile] = useState<CustomerFileType>();
	const sidebarCustomerFileAutocompleteInstance = useAutocomplete();

	const [fetchCustomerFiles, { networkStatus: fetchCustomerFilesNetworkStatus }] = useLazyQuery<FetchCustomerFilesType>(
		FETCH_CUSTOMER_FILES,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const [fetchCustomerFilesForStandaloneModule, { networkStatus: fetchCustomerFilesForStandaloneModuleNetworkStatus }] =
		useLazyQuery<FetchCustomerFilesByModuleCodeType>(FETCH_CUSTOMER_FILES_BY_MODULE_CODE, {
			notifyOnNetworkStatusChange: true,
		});

	const customerFileDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: CustomerFileType[];
			count: number;
		}> => {
			const { data, error } = standaloneModule
				? await fetchCustomerFilesForStandaloneModule({
						variables: {
							moduleCode: standaloneModule,
							page: {
								limit,
								offset,
							},
							search,
						},
				  })
				: await fetchCustomerFiles({
						variables: {
							page: {
								limit,
								offset,
							},
							search,
						},
				  });

			if (error) {
				throw error;
			}

			if (standaloneModule) {
				const { count = 0, items = [] } = (data as FetchCustomerFilesByModuleCodeType)?.customerFilesByModuleCode ?? {};

				return { count, items };
			}

			const { count = 0, items = [] } = (data as FetchCustomerFilesType)?.customerFiles ?? {};

			return { count, items };
		},
		[fetchCustomerFiles, fetchCustomerFilesForStandaloneModule, standaloneModule],
	);

	const handleCustomerFileChange = (selectedCustomerFile?: CustomerFileType) => {
		if (!selectedCustomerFile) {
			throw new Error("Customer file can't be cleared");
		}

		changeSessionCustomerFile(selectedCustomerFile);
		setCustomerFile(selectedCustomerFile);
	};

	useEffect(() => {
		if (sessionCustomerFile) {
			setCustomerFile(sessionCustomerFile);
		}
	}, [sessionCustomerFile]);

	// Handling navigating from customerFile-not-found error to reset the selected customerFile
	useEffect(() => {
		if (prevPath?.includes('/error/404?error=customerFile-not-found')) {
			(async () => {
				await refetchSession();
			})().catch((error) => {
				throw error;
			});
		}
	}, [prevPath, refetchSession]);

	useEffect(() => {
		if (
			fetchCustomerFilesNetworkStatus === NetworkStatus.refetch ||
			fetchCustomerFilesForStandaloneModuleNetworkStatus === NetworkStatus.refetch
		) {
			sidebarCustomerFileAutocompleteInstance.reload();
		}
	}, [
		fetchCustomerFilesForStandaloneModuleNetworkStatus,
		fetchCustomerFilesNetworkStatus,
		sidebarCustomerFileAutocompleteInstance,
	]);

	return (
		<Sidebar.Autocomplete<CustomerFileType>
			autocomplete={sidebarCustomerFileAutocompleteInstance}
			placeholder="Aucune entreprise"
			dataSource={customerFileDataSource}
			getOptionLabel={({ code, name }) => `${code} - ${name}`}
			icon={<Icon path={mdiDomain} />}
			onChange={handleCustomerFileChange}
			optionWidth={300}
			title="Dossiers clients"
			value={customerFile}
			disableClearable
		/>
	);
};

export default SidebarCustomerFileSelector;
