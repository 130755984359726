import { Icon } from '@elipssolution/harfang';
import { mdiFileSign } from '@mdi/js';

import brochurePages from './pages/pages';
import { ModuleType } from '../../types/module';
import { ModuleCodeEnum } from '../../types/widget';

const brochureModule: ModuleType = {
	code: ModuleCodeEnum.BROCHURE,
	icon: <Icon path={mdiFileSign} />,
	name: 'Process Plaquette',
	route: `/${ModuleCodeEnum.BROCHURE}`,
	subItems: brochurePages,
};

export default brochureModule;
