import { Icon } from '@elipssolution/harfang';
import { mdiFileChart, mdiFileEye, mdiFileUpload } from '@mdi/js';

import { NavigationItemType } from '../../../types/module';

const pages: NavigationItemType[] = [
	{
		icon: <Icon path={mdiFileUpload} />,
		name: 'Tiers',
		route: '/thirdParties',
	},
	{
		icon: <Icon path={mdiFileEye} />,
		name: 'Comptes',
		route: '/accounts',
	},
	{
		icon: <Icon path={mdiFileChart} />,
		name: 'Analytiques',
		route: '/analyticalSections',
	},
];

export default pages;
