import { ApolloError, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemCheckbox,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiPencil, mdiPlus } from '@mdi/js';
import { Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import DomainNameField from './DomainNameField';
import ModulesAvailabilityTable from './ModulesAvailabilityTable';
import PermissionWall from '../../../../components/PermissionWall';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../types/domain';
import { PermissionEnum } from '../../../../types/permission';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { CREATE_DOMAIN, CreateDomainType, REMOVE_DOMAIN, UPDATE_DOMAIN, UpdateDomainType } from '../../../api/domain';

const ErrorWrapper = styled('div')(({ theme }) => ({
	overflow: 'auto',

	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	padding: theme.spacing(1),
	gap: theme.spacing(1),
	margin: theme.spacing(2, 'auto'),

	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

const ButtonWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

type FormType = {
	isDefault: DomainType['isDefault'];
	name: DomainType['name'];
};

type DomainProps = {
	domain?: DomainType;
};

const DomainForm = ({ domain: initialDomain }: DomainProps) => {
	const { back } = useSettingsDialog();

	const {
		clearErrors,
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
		setError,
	} = useForm<FormType>();

	const [domain, setDomain] = useState(initialDomain);

	const { id, isDefault, name } = domain ?? {};

	const [errorMessage, setErrorMessage] = useState('');
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);
	const [isCreateDomainSucceeded, setIsCreateDomainSucceeded] = useState(false);
	const [isUpdateDomainSucceeded, setIsUpdateDomainSucceeded] = useState(false);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const [createDomain, { loading: isCreateDomainLoading }] = useMutation<CreateDomainType>(CREATE_DOMAIN, {
		onCompleted: ({ createDomain: domainResult }) => {
			setDomain(domainResult);
			setIsCreateDomainSucceeded(true);
			setTimeout(() => setIsCreateDomainSucceeded(false), 3000);
		},
	});

	const [updateDomain, { loading: isUpdateDomainLoading }] = useMutation<UpdateDomainType>(UPDATE_DOMAIN, {
		onCompleted: ({ updateDomain: domainResult }) => {
			setDomain(domainResult);
			setIsUpdateDomainSucceeded(true);
			setTimeout(() => setIsUpdateDomainSucceeded(false), 3000);
		},
	});

	const [removeDomain, { loading: isRemoveDomainLoading }] = useMutation(REMOVE_DOMAIN, {
		onCompleted: back,
		onError: (error: ApolloError) =>
			setConfirmationDialogDetails((prevValue) => ({
				...prevValue,
				dialogErrorMessage: generateErrorInformations({ error, resource: 'removeDomain' })?.message,
			})),
	});

	const isLoading = useMemo(
		() => isCreateDomainLoading || isUpdateDomainLoading,
		[isCreateDomainLoading, isUpdateDomainLoading],
	);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveDomainLoading || !isValid || !isDirty,
		[isDirty, isRemoveDomainLoading, isValid],
	);

	const startIcon = useMemo(() => <Icon path={domain ? mdiPencil : mdiPlus} />, [domain]);

	const onSubmit = useCallback(
		(values: FieldValues) =>
			(domain
				? updateDomain({
						variables: {
							updateDomainInput: {
								id: domain.id,
								...values,
							},
						},
				  })
				: createDomain({
						variables: {
							createDomainInput: {
								...values,
							},
						},
				  })
			).catch((error: ApolloError) =>
				setErrorMessage(
					generateErrorInformations({ error, resource: domain ? 'updateDomain' : 'createDomain' })?.message,
				),
			),
		[domain, updateDomain, createDomain],
	);

	const deleteDomain = useCallback(
		() =>
			removeDomain({
				variables: {
					removeDomainId: id,
				},
			}),
		[removeDomain, id],
	);

	const handleDomainNameError = useCallback(
		(error?: string) => (error ? setError('name', { message: error }) : clearErrors('name')),
		[clearErrors, setError],
	);

	const submitButtonLabel = useMemo(() => {
		if (domain) {
			if (isCreateDomainSucceeded) return 'Domaine ajouté';
			if (isUpdateDomainSucceeded) return 'Domaine modifié';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [isCreateDomainSucceeded, isUpdateDomainSucceeded, domain]);

	useEffect(() => {
		reset({ isDefault, name });
	}, [domain, isDefault, name, reset]);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveDomainLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveDomainLoading,
				persistantErrorMessage: dialogErrorMessage,
				onClick: deleteDomain,
				color: 'error',
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, deleteDomain, dialogErrorMessage, isRemoveDomainLoading],
	);

	return (
		<SettingsDialogPage title={name ?? "Ajout d'un domaine"}>
			<SettingsGroup>
				<DomainNameField initialDomainName={name} control={control} onError={handleDomainNameError} />

				<Controller
					name="isDefault"
					control={control}
					defaultValue={false}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le domaine est celui par défaut."
							disabled={isDefault}
							disabledTooltipContent={isDefault ? 'Vous ne pouvez pas modifier le domaine par défaut.' : undefined}
							label="Défaut"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<ButtonWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={startIcon}
					variant="contained"
				>
					{submitButtonLabel}
				</Button>

				{domain && (
					<Tooltip
						content={
							isDefault ? 'Vous ne pouvez pas supprimer un domaine configuré comme domaine par défaut' : undefined
						}
						placement="top"
					>
						<div>
							<Button
								color="error"
								disabled={isDefault || isLoading}
								onClick={openConfirmationDialog}
								variant="outlined"
							>
								Supprimer
							</Button>
						</div>
					</Tooltip>
				)}
			</ButtonWrapper>

			{id && (
				<PermissionWall
					permissionCodes={[
						PermissionEnum.DOCUMENT_SETTING_STORAGE,
						PermissionEnum.DOCUMENT_SETTING_TAG,
						PermissionEnum.QUICKENTRY_SETTING,
						PermissionEnum.ACCOUNTING_SETTING,
						PermissionEnum.SIGN_ACCESS,
					]}
				>
					<ModulesAvailabilityTable domainId={id} onError={setErrorMessage} />
				</PermissionWall>
			)}

			{errorMessage && (
				<ErrorWrapper>
					<Icon path={mdiAlertCircle} />
					<Typography>{errorMessage}</Typography>
				</ErrorWrapper>
			)}

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				content="Les dossiers attachés à ce domaine seront attachés au domaine par défaut."
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isOpen}
				title={`Êtes-vous sûr de vouloir supprimer le domaine ${name || ''} ?`}
			/>
		</SettingsDialogPage>
	);
};
export default DomainForm;
