import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import modulesConfig from '../../modules/modules';
import { ModuleType } from '../../types/module';
import { FETCH_MODULES, FetchModulesType } from '../api/module';
import { useApollo } from '../components/ApolloProvider';

export const useModules = () => {
	const { customerFileId: apolloCustomerFileId } = useApollo();

	const { data: fetchModulesData } = useQuery<FetchModulesType>(FETCH_MODULES, {
		skip: !apolloCustomerFileId,
	});

	const modules = useMemo(() => {
		if (!apolloCustomerFileId) return [];

		const { modules: fetchedModules = [] } = fetchModulesData || {};

		return fetchedModules.reduce((acc, { code }) => {
			const moduleConfig = modulesConfig.find(({ code: configCode }) => configCode === code);

			if (!moduleConfig) return acc;

			return [
				...acc,
				{
					...module,
					...moduleConfig,
				},
			];
		}, [] as ModuleType[]);
	}, [apolloCustomerFileId, fetchModulesData]);

	return modules;
};
