import { useMutation } from '@apollo/client';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogProps,
	FileUpload,
	Icon,
	IconButton,
	TextField,
} from '@elipssolution/harfang';
import { mdiClose, mdiSendOutline } from '@mdi/js';
import { Avatar, Stack, Typography, styled } from '@mui/material';
import Image from 'next/image';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { browserName, browserVersion } from 'react-device-detect';
import { Control, Controller, useForm } from 'react-hook-form';
import sanitize from 'sanitize-html';

import { useSession } from './SessionProvider';
import { ModuleType } from '../../types/module';
import { ModuleCodeEnum } from '../../types/widget';
import { DIALOG_CLOSE_DELAY } from '../../utils/dialogCloseDelay';
import { SEND_SUGGESTIONS_MAIL, SendSuggestionsMailType } from '../api/suggestion';
import useModulesDataSource from '../hooks/useModulesDataSource';
import { formatFileSize } from '../utils/file';

const SuggestionTextField = styled(TextField)({
	'& > div > textarea': {
		minHeight: 150,
	},
});

const UploadedImageWrapper = styled('div')(({ theme: { shape, spacing, palette } }) => ({
	flex: 1,

	borderRadius: shape.borderRadius * 2,
	border: `2px dashed ${palette.divider}`,

	padding: spacing(1),

	height: 76,

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',

	'& > div:first-of-type > span': {
		borderRadius: shape.borderRadius,
	},
}));

type SupportItemProps = {
	action: JSX.Element;
	content: JSX.Element;
};

const SupportItem = ({ action, content }: SupportItemProps) => (
	<Stack flexDirection="row" gap={2}>
		<Avatar src="/logoCollapsed" />
		<Stack width="100%">
			<Typography variant="subtitle2">Équipe Support</Typography>
			{content}
			{action}
		</Stack>
	</Stack>
);

type SuggestionsFormType = {
	type: 'enhancement' | 'bug' | null;
	module: Pick<ModuleType, 'code' | 'name'>;
	message: string | null;
	image?: Blob | null;
};

type SupportModuleItemProps = {
	control: Control<SuggestionsFormType>;
	type: 'enhancement' | 'bug';
};

const SupportModuleItem = ({ control, type }: SupportModuleItemProps) => {
	const { dataSource: modulesDataSource } = useModulesDataSource();

	return (
		<SupportItem
			content={
				<>
					{type === 'enhancement' && (
						<Typography>
							Super ! Suite est en constante évolution, n&apos;hésitez pas à nous faire parvenir vos idées.
						</Typography>
					)}
					{type === 'bug' && <Typography>Aïe ! Nous allons essayer de le corriger au plus tôt !</Typography>}

					<Typography>
						Dans un premier temps, quel module de Suite est concerné par cette proposition d&apos;évolution ?
					</Typography>
				</>
			}
			action={
				<Stack flexDirection="row" justifyContent="flex-end" gap={1} marginTop={2}>
					<Controller
						control={control}
						render={({ field }) => (
							<Autocomplete<Pick<ModuleType, 'code' | 'name'>>
								{...field}
								dataKey="code"
								dataSource={modulesDataSource}
								getOptionLabel={({ name }) => name}
								label="Module concerné"
								sx={{
									width: 300,
								}}
							/>
						)}
						rules={{ required: true }}
						name="module"
					/>
				</Stack>
			}
		/>
	);
};

type SuggestionsDialogProps = {
	open: DialogProps['open'];
	onClose: () => void;
};

const SupportDialog = ({ open, onClose }: SuggestionsDialogProps) => {
	const {
		user: { email, firstName, lastName },
		customerFile,
	} = useSession();
	const { data } = useNextAuthSession();
	const { access_token } = data ?? {};

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
		watch,
	} = useForm<SuggestionsFormType>();

	const formType = watch('type');
	const formModule = watch('module');
	const formImage = watch('image');

	const imageObjectUrl = useMemo(() => formImage && URL.createObjectURL(formImage), [formImage]);

	const clientInformations = useMemo(
		() =>
			`<p><strong>Nom de l'utilisateur :</strong> ${`${
				firstName ?? ''
			} ${lastName}`.trim()}</p><p><strong>Email de l'utilisateur :</strong> ${email}</p><p><strong>Dossier :</strong> ${
				customerFile?.code ?? ''
			} - ${
				customerFile?.name ?? ''
			}<strong></p><p>Navigateur :</strong> ${browserName}@${browserVersion}</p><p><strong>Module concerné :</strong> ${
				formModule?.name
			}</p><br/>`,
		[customerFile?.code, customerFile?.name, email, firstName, formModule, lastName],
	);

	const [hasSucceeded, setHasSucceeded] = useState(false);
	const [error, setError] = useState<string>();

	const handleDialogClose = useCallback(() => {
		onClose();
		setHasSucceeded(false);
		setError(undefined);
	}, [onClose]);

	const [sendEnhancementSuggestion, { loading: isMailSending }] = useMutation<SendSuggestionsMailType>(
		SEND_SUGGESTIONS_MAIL,
		{
			onCompleted: () => {
				setHasSucceeded(true);
				setTimeout(handleDialogClose, DIALOG_CLOSE_DELAY);
			},
			onError: () => setError("Erreur lors de l'envoi des suggestions"),
		},
	);

	const sendBugSuggestion = useCallback(
		async ({
			description,
			imageToUpload,
			moduleCode,
		}: {
			description: SuggestionsFormType['message'];
			imageToUpload: SuggestionsFormType['image'];
			moduleCode: ModuleCodeEnum;
		}) => {
			if (!access_token || !customerFile || !description) return;

			const headers = new Headers();
			headers.append('Authorization', `Bearer ${access_token}`);
			headers.append('customer-file-id', customerFile.id);

			const body = new FormData();

			if (imageToUpload) {
				body.append('file', imageToUpload);
			}

			const subject = `SUITE V2 - ${customerFile.code} - Bug`;

			const ticketingURL = new URL('/ticketing', window.location.href);
			ticketingURL.searchParams.append('subject', subject);
			ticketingURL.searchParams.append('description', description);
			ticketingURL.searchParams.append('moduleCode', moduleCode);

			const response = await fetch(ticketingURL, {
				method: 'POST',
				headers,
				...(imageToUpload && { body }),
			});

			if (!response.ok) {
				throw new Error('Unable to submit ticket');
			}

			setHasSucceeded(true);
			setTimeout(handleDialogClose, DIALOG_CLOSE_DELAY);
		},
		[handleDialogClose, access_token, customerFile],
	);

	const onSubmit = useCallback(
		async ({ image, message, module }: SuggestionsFormType) => {
			if (!message) return;

			const suggestionMessage = `${clientInformations}<p>${sanitize(message)}</p>`;

			if (formType === 'enhancement') {
				await sendEnhancementSuggestion({
					variables: {
						sendSuggestionsMailInput: {
							suggestions: suggestionMessage,
						},
					},
				});
			}

			if (formType === 'bug') {
				const { code: moduleCode } = module;

				await sendBugSuggestion({ imageToUpload: image, description: suggestionMessage, moduleCode });
			}
		},
		[sendEnhancementSuggestion, sendBugSuggestion, clientInformations, formType],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isMailSending,
				label: hasSucceeded ? 'Fermer' : 'Annuler',
				onClick: handleDialogClose,
			},
			{
				disabled: !isValid,
				error: !!error,
				label: hasSucceeded ? 'Suggestion envoyée' : 'Envoyer la suggestion',
				loading: isMailSending,
				onClick: handleSubmit(onSubmit),
				persistantErrorMessage: error,
				startIcon: <Icon path={mdiSendOutline} size="small" />,
				success: hasSucceeded,
				variant: 'contained',
			},
		],
		[error, handleDialogClose, handleSubmit, hasSucceeded, isMailSending, isValid, onSubmit],
	);

	useEffect(() => {
		!open &&
			reset({
				type: null,
				message: null,
				image: null,
			});
	}, [reset, open]);

	useEffect(
		() => () => {
			imageObjectUrl && URL.revokeObjectURL(imageObjectUrl);
		},
		[imageObjectUrl],
	);

	return (
		<Dialog actionsDialog={actionsDialog} title="Support" open={open} onClose={handleDialogClose} fullWidth>
			<Stack gap={1}>
				<SupportItem
					content={
						<>
							<Typography>
								Merci de prendre le temps de nous faire part de votre suggestion. Nous sommes constamment à la recherche
								de moyens pour améliorer et faire évoluer Suite et votre avis nous aide grandement !
							</Typography>
							<Typography>
								Avant de commencer, pourriez-vous nous indiquer si votre suggestion concerne une évolution ou un bug ?
							</Typography>
						</>
					}
					action={
						<Stack flexDirection="row" justifyContent="flex-end" gap={1} marginTop={2}>
							<Controller
								control={control}
								name="type"
								render={() => {
									const handleChange = (newType: 'enhancement' | 'bug') => {
										newType !== formType && reset({ type: newType });
									};

									return (
										<>
											<Button
												color={formType === 'enhancement' ? 'primary' : 'inherit'}
												onClick={() => handleChange('enhancement')}
												variant={formType === 'enhancement' ? 'contained' : 'outlined'}
											>
												Evolution
											</Button>
											<Button
												color={formType === 'bug' ? 'primary' : 'inherit'}
												onClick={() => handleChange('bug')}
												variant={formType === 'bug' ? 'contained' : 'outlined'}
											>
												Bug
											</Button>
										</>
									);
								}}
								rules={{ required: true }}
							/>
						</Stack>
					}
				/>

				{formType && <SupportModuleItem control={control} type={formType} />}

				{formModule && formType === 'enhancement' && (
					<SupportItem
						content={
							<Typography>
								Veuillez être le plus exhaustif possible afin que vos idées soient compréhensibles, interprétables et
								réalisables.
							</Typography>
						}
						action={
							<Stack marginTop={2}>
								<Controller
									control={control}
									name="message"
									render={({ field: { value, ...field } }) => (
										<SuggestionTextField
											{...field}
											disabled={!!error}
											placeholder="Votre suggestion..."
											value={value ?? ''}
											multiline
										/>
									)}
									rules={{ required: true }}
								/>
							</Stack>
						}
					/>
				)}
				{formModule && formType === 'bug' && (
					<SupportItem
						content={
							<Typography>
								Veuillez nous fournir le plus de détails possible afin de rendre votre bug reproductible et faciliter sa
								résolution (étapes de reproduction, capture d&apos;écran, etc.).
							</Typography>
						}
						action={
							<Stack gap={1} marginTop={2}>
								<Controller
									control={control}
									name="message"
									render={({ field: { value, ...field } }) => (
										<SuggestionTextField
											{...field}
											disabled={!!error}
											placeholder="Description du bug..."
											value={value ?? ''}
											multiline
										/>
									)}
									rules={{ required: true }}
								/>
								<Controller
									control={control}
									name="image"
									render={({ field: { onChange, value } }) => {
										const handleChange = (uploadedFiles?: File[]) =>
											onChange(uploadedFiles ? uploadedFiles[0] : undefined);

										return !imageObjectUrl || !value ? (
											<FileUpload
												acceptedTypes={{
													'image/jpeg': [],
													'image/png': [],
												}}
												onChange={handleChange}
												maxFiles={1}
											/>
										) : (
											<UploadedImageWrapper>
												<Stack alignItems="center" flexDirection="row" gap={2}>
													<Image
														alt="Prévisualisation de l'image déposée"
														height={56}
														width={56}
														src={imageObjectUrl}
														objectFit="scale-down"
													/>
													<Stack>
														<Typography>{value.name}</Typography>
														<Typography variant="caption">{formatFileSize(value.size)}</Typography>
													</Stack>
												</Stack>
												<IconButton onClick={() => handleChange()}>
													<Icon path={mdiClose} size="small" />
												</IconButton>
											</UploadedImageWrapper>
										);
									}}
								/>
							</Stack>
						}
					/>
				)}
			</Stack>
		</Dialog>
	);
};

export default SupportDialog;
