import { Stack } from '@mui/material';

import FinishedProceduresTable from './FinishedProceduresTable';
import ProceduresTableHeader from '../../../components/ProceduresTableHeader';

const Finished = () => (
	<Stack height="100%" gap={2}>
		<ProceduresTableHeader />

		<FinishedProceduresTable />
	</Stack>
);

export default Finished;
