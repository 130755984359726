import { gql } from '@apollo/client';

import { SiteType } from '../types/site';

export const SITE_FIELDS = `
    id
    name
`;

// QUERIES

export type FetchLegalServiceUsedSitesType = {
	brochure_legalServiceUsedSites: {
		items: SiteType[];
		offset: number;
		limit: number;
		count: number;
	};
};

export const FETCH_LEGAL_SERVICE_USED_SITES = gql`
	query Brochure_legalServiceUsedSites(
        $page: Page
        $search:String
		$isBrochureArchived: Boolean
	) {
		brochure_legalServiceUsedSites(
            page: $page
            search:$search
			isBrochureArchived: $isBrochureArchived
		) {
			items {
				${SITE_FIELDS}
			}
			offset
            limit
            count
		}
	}
`;

export type FetchInvoicingServiceUsedSitesType = {
	brochure_invoicingServiceUsedSites: {
		count: number;
		items: SiteType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_INVOICING_SERVICE_USED_SITES = gql`
	query Brochure_invoicingServiceUsedSites($search: String, $isBrochureArchived: Boolean, $page: Page!) {
		brochure_invoicingServiceUsedSites(search: $search, isBrochureArchived: $isBrochureArchived, page: $page) {
			items {
				${SITE_FIELDS}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchUsedSitesType = {
	brochure_usedSites: {
		items: SiteType[];
		offset: number;
		limit: number;
		count: number;
	};
};

export const FETCH_USED_SITES = gql`
	query Brochure_usedSites(
		$isBrochureArchived: Boolean
        $page: Page
        $search:String
	) {
		brochure_usedSites(
			isBrochureArchived: $isBrochureArchived
            page: $page
            search:$search
		) {
			items {
				${SITE_FIELDS}
			}
			offset
            limit
            count
		}
	}
`;
