import { useTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useState } from 'react';

import OngoingProceduresTable from './OngoingProceduresTable';
import ProceduresTableHeader from '../../../components/ProceduresTableHeader';
import { ProcedureType } from '../../../types/procedure';

const Ongoing = () => {
	const tableInstance = useTable();
	const handleTableReload = () => tableInstance.reload();

	const [procedureToEditId, setProcedureToEditId] = useState<ProcedureType['id']>();

	const closeProcedureDialog = () => {
		setProcedureToEditId(undefined);
		handleTableReload();
	};

	return (
		<Stack height="100%" gap={2}>
			<ProceduresTableHeader
				isProcedureDialogOpen={!!procedureToEditId}
				procedureId={procedureToEditId}
				onCloseProcedureDialog={closeProcedureDialog}
			/>

			<OngoingProceduresTable onDraftProcedureClick={(id) => setProcedureToEditId(id)} tableInstance={tableInstance} />
		</Stack>
	);
};

export default Ongoing;
