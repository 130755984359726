import { Icon } from '@elipssolution/harfang';
import { mdiFileEye } from '@mdi/js';

import accountingPages from './pages/pages';
import widgets from './widgets/widgets';
import { ModuleType } from '../../types/module';
import { PermissionEnum } from '../../types/permission';
import { ModuleCodeEnum } from '../../types/widget';

const accountingModule: ModuleType = {
	code: ModuleCodeEnum.ACCOUNTING,
	icon: <Icon path={mdiFileEye} />,
	name: 'Comptabilité',
	permissionCodes: [PermissionEnum.ACCOUNTING_READ],
	route: `/${ModuleCodeEnum.ACCOUNTING}`,
	subItems: accountingPages,
	widgets,
};

export default accountingModule;
