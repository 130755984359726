import { Icon } from '@elipssolution/harfang';
import { mdiFileEye, mdiFileUpload } from '@mdi/js';

import { NavigationItemType } from '../../../types/module';
import { PermissionEnum } from '../../../types/permission';

const pages: NavigationItemType[] = [
	{
		icon: <Icon path={mdiFileUpload} />,
		name: 'Dépôt',
		permissionCodes: [PermissionEnum.DOCUMENT_UPLOAD],
		route: '/storageUpload',
	},
	{
		icon: <Icon path={mdiFileEye} />,
		name: 'Consultation GED',
		permissionCodes: [PermissionEnum.DOCUMENT_DOWNLOAD],
		route: '/storageDownload',
	},
];

export default pages;
