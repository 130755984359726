import { Dialog, DialogProps, Icon } from '@elipssolution/harfang';
import { mdiCheck, mdiExclamation } from '@mdi/js';
import { alpha, ButtonBase, Stack, styled, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import clsx from 'clsx';
import { ReactNode } from 'react';

const StepsContainer = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	marginBottom: spacing(3),
	gap: spacing(2),
}));

const StyledStepItem = styled(ButtonBase)(({ theme: { palette, spacing, shape } }) => ({
	display: 'flex',
	padding: spacing(1.5),
	alignItems: 'center',
	gap: spacing(1),
	cursor: 'pointer',
	borderRadius: shape.borderRadius * 2,
	'&.isCurrent': {
		fontWeight: 'bold',
	},
	'&:hover': {
		backgroundColor: palette.action.hover,
		'@media (hover: none)': {
			backgroundColor: 'transparent',
		},
	},
}));

const InfoWrapper = styled('div')(({ theme: { shape, spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: spacing(1),
	borderRadius: shape.borderRadius * 2,
}));

const ErrorWrapper = styled(InfoWrapper)(({ theme: { palette } }) => ({
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
}));

const WarningWrapper = styled(InfoWrapper)(({ theme: { palette } }) => ({
	color: palette.warning.main,
	backgroundColor: `${palette.warning.main}1A`,
}));

const StepNumber = styled('div')(({ theme: { palette, transitions } }) => ({
	width: '35px',
	height: '35px',
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontWeight: 'bold',
	backgroundColor: palette.grey[300],
	color: palette.getContrastText(alpha(palette.grey[300], 0.5)),
	'&.hasError': {
		backgroundColor: palette.error.main,
		color: palette.getContrastText(alpha(palette.error.main, 0.5)),
	},
	'&:not(.hasError)&.isCurrent': {
		backgroundColor: alpha(palette.primary.main, 0.5),
		color: palette.getContrastText(alpha(palette.primary.main, 0.5)),
	},
	'&:not(.hasError)&:not(.isCurrent).isValid': {
		backgroundColor: `${alpha(green[200], 0.6)}`,
		color: palette.getContrastText(green[200]),
	},
	'& svg': {
		position: 'relative',
		animation: `revealEffect 1s ${transitions.easing.easeInOut} forwards`,
		'@keyframes revealEffect': {
			'0%': {
				opacity: 0,
				clipPath: 'inset(0 100% 0 0)',
			},
			'100%': {
				opacity: 1,
				clipPath: 'inset(0 0 0 0)',
			},
		},
	},
}));

const Line = styled('div')(({ theme: { palette } }) => ({
	width: '48%',
	height: '2px',
	backgroundColor: 'rgba(0, 0, 0, 0.1)',
	color: 'rgba(0, 0, 0, 0.26)',
	'&.isBetweenValidSteps': {
		backgroundColor: alpha(palette.primary.main, 0.2),
		color: palette.getContrastText(alpha(palette.primary.main, 0.2)),
	},
}));

const StepContent = styled('div')({
	'&:not(.isVisible)': {
		display: 'none',
	},
});

type StepType = {
	number: number;
	label: string;
	content: ReactNode;
	isValid?: boolean;
	hasError?: boolean;
};

type StepItemProps = {
	isValid: boolean;
	hasError: boolean;
	isCurrent: boolean;
	label: string;
	stepNumber: ReactNode;
	onClick: (index: number) => void;
	index: number;
};

const StepItem = ({ isValid = false, isCurrent, label, stepNumber, onClick, index, hasError }: StepItemProps) => (
	<StyledStepItem
		key={index}
		onClick={() => onClick(index)}
		className={clsx({
			isCurrent,
		})}
	>
		<StepNumber
			className={clsx({
				isCurrent,
				isValid,
				hasError,
			})}
		>
			{isValid || hasError ? <Icon path={isValid ? mdiCheck : mdiExclamation} size="small" /> : stepNumber}
		</StepNumber>
		<Typography>{label}</Typography>
	</StyledStepItem>
);

const StepDivider = ({ isBetweenValidSteps }: { isBetweenValidSteps: boolean }) => (
	<Line className={clsx({ isBetweenValidSteps })} />
);

type DialogStepperProps = {
	onClose: () => void;
	dialogTitle: string;
	actionsDialog: DialogProps['actionsDialog'];
	extraInfos: DialogProps['extraInfos'];
	dialogStatus: DialogProps['status'];
	isOpen: boolean;
	currentStep: number;
	onCurrentStepChange: (stepIndex: number) => void;
	steps: StepType[];
	commonError?: string;
	warningMessage?: string;
};

const DialogStepper = ({
	onClose,
	dialogTitle,
	actionsDialog,
	extraInfos,
	dialogStatus,
	isOpen,
	currentStep,
	onCurrentStepChange,
	steps,
	commonError,
	warningMessage,
}: DialogStepperProps) => (
	<Dialog
		fullWidth
		actionsDialog={actionsDialog}
		title={dialogTitle}
		extraInfos={extraInfos}
		open={isOpen}
		onClose={onClose}
		maxWidth="lg"
		status={dialogStatus}
	>
		<StepsContainer>
			{steps.map(({ number, label, isValid = false, hasError = false }, index) => (
				<>
					<StepItem
						key={number}
						isCurrent={index === currentStep}
						isValid={isValid && !hasError}
						onClick={onCurrentStepChange}
						index={index}
						label={label}
						stepNumber={number}
						hasError={hasError}
					/>
					{index < steps.length - 1 && <StepDivider isBetweenValidSteps={(isValid && steps[index].isValid) || false} />}
				</>
			))}
		</StepsContainer>
		<Stack gap={2}>
			{steps.map((step) => (
				<StepContent key={step.number} className={clsx({ isVisible: currentStep === step.number - 1 })}>
					{step.content}
				</StepContent>
			))}

			{commonError && <ErrorWrapper>{commonError}</ErrorWrapper>}
			{warningMessage && <WarningWrapper>{warningMessage}</WarningWrapper>}
		</Stack>
	</Dialog>
);

export default DialogStepper;
