import { Icon } from '@elipssolution/harfang';
import {
	mdiArchive,
	mdiChartBar,
	mdiCogClockwise,
	mdiFileSign,
	mdiHome,
	mdiReceiptTextOutline,
	mdiScaleBalance,
	mdiTimerSandEmpty,
} from '@mdi/js';

import { NavigationItemType } from '../../../types/module';
import { PermissionEnum } from '../../../types/permission';

const pages: NavigationItemType[] = [
	{
		icon: <Icon path={mdiFileSign} />,
		name: 'Général',
		route: '/general',
		permissionCodes: [PermissionEnum.BROCHURE_GENERAL],
		subItems: [
			{
				icon: <Icon path={mdiChartBar} />,
				name: 'Statistiques',
				permissionCodes: [PermissionEnum.BROCHURE_STATISTICS],
				route: '/statistics',
			},
			{
				icon: <Icon path={mdiCogClockwise} />,
				name: 'En cours',
				route: '/ongoing',
			},
			{
				icon: <Icon path={mdiTimerSandEmpty} />,
				name: 'Actions attendues',
				route: '/awaitedActions',
			},
			{
				icon: <Icon path={mdiArchive} />,
				name: 'Archives',
				route: '/archives',
			},
		],
	},
	{
		icon: <Icon path={mdiScaleBalance} />,
		name: 'Juridique',
		route: '/legal',
		permissionCodes: [PermissionEnum.BROCHURE_LEGAL],
		subItems: [
			{ icon: <Icon path={mdiHome} />, name: 'En cours', route: '/ongoing' },
			{
				icon: <Icon path={mdiArchive} />,
				name: 'Archives',
				route: '/archives',
			},
		],
	},
	{
		icon: <Icon path={mdiReceiptTextOutline} />,
		name: 'Facturation',
		route: '/invoicing',
		permissionCodes: [PermissionEnum.BROCHURE_INVOICING],
		subItems: [
			{ icon: <Icon path={mdiHome} />, name: 'En cours', route: '/ongoing' },
			{
				icon: <Icon path={mdiArchive} />,
				name: 'Archives',
				route: '/archives',
			},
		],
	},
];

export default pages;
