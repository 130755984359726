import { gql } from '@apollo/client';

import { ProcedureType } from '../types/procedure';

const PROCEDURE_BASE_FIELDS = `
    id
    name
    isPrivate
    status
    updatedAt
`;

const CREATOR_FIELDS = `
    id
    lastName
    firstName
`;

const SIGNER_INFO_FIELDS = `
    id
    firstName
    lastName
    email
    rank
`;

const SIGNER_FIELDS = `
    ${SIGNER_INFO_FIELDS}
    rejectedAt
    signedAt
`;
// QUERIES

export type FetchProcedureType = {
	sign_procedure: ProcedureType;
};

export const FETCH_PROCEDURE = gql`
	query Sign_procedure($signProcedureId: ID!) {
		sign_procedure(id: $signProcedureId) {
			${PROCEDURE_BASE_FIELDS}
            comment
			createdBy {
				${CREATOR_FIELDS}
			}
			signers {
				${SIGNER_FIELDS}	
			}      
            documents {
                id
                filename
                signers {
                    ${SIGNER_FIELDS}   
                }
            }
		}
	}
`;

export type FetchDraftProcedureType = {
	sign_procedure: ProcedureType;
};

export const FETCH_DRAFT_PROCEDURE = gql`
	query Sign_procedure($signProcedureId: ID!) {
		sign_procedure(id: $signProcedureId) {
			${PROCEDURE_BASE_FIELDS}
            comment
			createdBy {
				${CREATOR_FIELDS}
			}
			signers {
				${SIGNER_FIELDS}	
			}
            hasError      
            documents {
                id
                filename
                fields {
                    id
                    type
                    page
                    x
                    y
                    width
                    height
                    signer {
                        ${SIGNER_FIELDS} 
                    }
                    text
                    mention
                }
            }
		}
	}
`;

export type FetchOngoingAndDraftProceduresType = {
	sign_ongoingAndDraftProcedures: {
		items: ProcedureType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_ONGOING_AND_DRAFT_PROCEDURES = gql`
    query Sign_ongoingAndDraftProcedures($page: Page!, $orderBy: OrderBy, $search: String, $filter: sign_OngoingProcedureFilterInput) {
        sign_ongoingAndDraftProcedures(page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
            items {
                ${PROCEDURE_BASE_FIELDS}
                createdBy {
                    ${CREATOR_FIELDS}
                }
                signers {
                    ${SIGNER_FIELDS}
                }
            }
            count
            limit
            offset
        }
    }
`;

export type FetchCanceledProceduresType = {
	sign_canceledProcedures: {
		items: ProcedureType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CANCELED_PROCEDURES = gql`
    query Sign_canceledProcedures($page: Page!, $orderBy: OrderBy, $search: String, $filter: sign_ProcedureFilterInput) {
        sign_canceledProcedures(page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
            items {
                ${PROCEDURE_BASE_FIELDS}
                createdBy {
                    ${CREATOR_FIELDS}
                }
                signers {
                    ${SIGNER_FIELDS}
                }
            }
            count
            limit
            offset
        }
    }
`;

export type FetchDeclinedProceduresType = {
	sign_declinedProcedures: {
		items: ProcedureType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DECLINED_PROCEDURES = gql`
    query Sign_declinedProcedures($page: Page!, $orderBy: OrderBy, $search: String, $filter: sign_ProcedureFilterInput) {
        sign_declinedProcedures(page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
            items {
                ${PROCEDURE_BASE_FIELDS}
                createdBy {
                    ${CREATOR_FIELDS}
                }
                signers {
                    ${SIGNER_FIELDS}
                }
            }
            count
            limit
            offset
        }
    }
`;

export type FetchFinishedProceduresType = {
	sign_finishedProcedures: {
		items: ProcedureType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_FINISHED_PROCEDURES = gql`
    query Sign_finishedProcedures($page: Page!, $orderBy: OrderBy, $search: String, $filter: sign_ProcedureFilterInput) {
        sign_finishedProcedures(page: $page, orderBy: $orderBy, search: $search, filter: $filter) {
            items {
                ${PROCEDURE_BASE_FIELDS}
                createdBy {
                    ${CREATOR_FIELDS}
                }
                signers {
                     ${SIGNER_FIELDS}
                }
            }
            count
            limit
            offset
        }
    }
`;

export type UpdateProcedureType = {
	sign_updateProcedure: { id: ProcedureType['id']; signers?: ProcedureType['signers'] };
};

export const UPDATE_PROCEDURE = gql`
	mutation Sign_updateProcedure($updateProcedureInput: sign_UpdateProcedureInput!) {
		sign_updateProcedure(updateProcedureInput: $updateProcedureInput) {
			id
            signers {
				${SIGNER_INFO_FIELDS}			
			}
		}
	}
`;

// MUTATIONS

export type CreateProcedureType = {
	sign_createProcedure: { id: ProcedureType['id']; signers?: ProcedureType['signers'] };
};

export const CREATE_PROCEDURE = gql`
	mutation Sign_createProcedure($createProcedureInput: sign_CreateProcedureInput!) {
		sign_createProcedure(createProcedureInput: $createProcedureInput) {
			id
			signers {
				${SIGNER_INFO_FIELDS}
			}
		}
	}
`;

export type RemindProcedureAwaitedSignersType = {
	sign_remindProcedureAwaitedSigners: { id: ProcedureType['id'] };
};

export const REMIND_PROCEDURE_AWAITED_SIGNERS = gql`
	mutation Sign_remindProcedureAwaitedSigners($signRemindProcedureAwaitedSignersId: ID!) {
		sign_remindProcedureAwaitedSigners(id: $signRemindProcedureAwaitedSignersId) {
			id
		}
	}
`;

export type CancelProcedureType = {
	sign_cancelProcedure: { id: ProcedureType['id'] };
};

export const CANCEL_PROCEDURE = gql`
	mutation Sign_cancelProcedure($signCancelProcedureId: ID!) {
		sign_cancelProcedure(id: $signCancelProcedureId) {
			id
		}
	}
`;

export type RemoveProcedureType = {
	sign_removeProcedure: { id: ProcedureType['id'] };
};

export const REMOVE_PROCEDURE = gql`
	mutation Sign_removeProcedure($signRemoveProcedureId: ID!) {
		sign_removeProcedure(id: $signRemoveProcedureId) {
			id
		}
	}
`;

export type ResetProcedureErrorStatusType = {
	sign_resetProcedureErrorStatus: { id: ProcedureType['id'] };
};

export const RESET_PROCEDURE_ERROR_STATUS = gql`
	mutation Sign_resetProcedureErrorStatus($id: ID!) {
		sign_resetProcedureErrorStatus(id: $id) {
			id
		}
	}
`;

export type StartProcedureType = {
	sign_startProcedure: { id: ProcedureType['id'] };
};

export const START_PROCEDURE = gql`
	mutation Sign_startProcedure($id: ID!) {
		sign_startProcedure(id: $id) {
			id
		}
	}
`;
