import { gql } from '@apollo/client';

import { yousignDocument } from '../types/document';

export type UpdateDocumentsType = {
	sign_updateDocuments: { yousignDocuments: yousignDocument[] };
};

export const UPDATE_DOCUMENTS = gql`
	mutation Sign_updateDocuments($saveDocumentsInput: sign_SaveDocumentsInput!) {
		sign_updateDocuments(saveDocumentsInput: $saveDocumentsInput) {
			yousignDocuments {
				documentYousignId
				fieldsYousignIds
			}
		}
	}
`;
