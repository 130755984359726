import { Icon, Sidebar } from '@elipssolution/harfang';
import { mdiChevronLeft } from '@mdi/js';
import { styled } from '@mui/material';
import clsx from 'clsx';
import getConfig from 'next/config';

import { useSession } from './SessionProvider';
import PermissionWall from '../../components/PermissionWall';
import { ModuleType, NavigationItemType } from '../../types/module';
import { ModuleCodeEnum } from '../../types/widget';
import useCustomRouter from '../hooks/useCustomRouter';
import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';

const SidebarItemWrapper = styled('div')(({ theme: { transitions } }) => ({
	display: 'flex',
	flexDirection: 'column',
	willChange: 'max-height, transform',

	'&:not(.hidden)': {
		transition: `max-height ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}, transform ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}`,
		maxHeight: 5000,
		transform: 'translate3d(0, 0, 0)',
	},

	'&.hidden': {
		transition: `max-height ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}, transform ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}`,
		maxHeight: 0,
		transform: 'translate3d(-100%, 0, 0)',
	},
}));

const RoutesWrapper = styled('div')(({ theme: { spacing, transitions } }) => ({
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	willChange: 'max-height, transform',

	'&:not(.shown)': {
		transition: `max-height ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}, transform ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}`,
		maxHeight: 0,
		transform: 'translate3d(100%, 0, 0)',
	},

	'&.shown': {
		gap: spacing(1),
		marginTop: spacing(1),
		transition: `max-height ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}, transform ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}`,
		maxHeight: 5000,
		transform: 'translate3d(0, 0, 0)',
	},
}));

type SidebarBodyProps = {
	modules: ModuleType[];
};

const SidebarBody = ({ modules }: SidebarBodyProps) => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { standaloneModule } = publicRuntimeConfig;
	const { pathname, push, query } = useCustomRouter();
	const { checkPermission } = useSession();
	const pathnameArray = pathname.split('/').slice(1);

	const navigateToRoute = ({ route, subItems }: Pick<NavigationItemType, 'route' | 'subItems'>) => {
		if (pathname.startsWith(route)) {
			push({
				pathname: '/',
				query,
			});
			return;
		}

		const { route: firstAllowedSubItemRoute, subItems: subSubItems } =
			subItems?.find(({ permissionCodes }) => permissionCodes?.some(checkPermission) ?? true) ?? {};

		const newPathnameArray = [...route.split('/'), firstAllowedSubItemRoute?.replace(/^\//, '')].filter(
			(item) => !!item && (standaloneModule ? item !== standaloneModule : true),
		);

		if (subSubItems && firstAllowedSubItemRoute) {
			navigateToRoute({ route: `/${newPathnameArray.join('/')}`, subItems: subSubItems });
			return;
		}

		push({
			pathname: `/${newPathnameArray.join('/')}`,
			query,
		});
	};

	return (
		<Sidebar.Body>
			{modules?.map(
				({
					code: moduleCode,
					route: moduleRoute,
					icon: moduleIcon,
					name: moduleName,
					subItems: moduleSubItems,
					permissionCodes: modulePermissionCodes = [],
				}) => {
					const isModuleSelected = pathname.startsWith(moduleRoute);
					const isShown = pathname === '/' || isModuleSelected;

					return (
						<PermissionWall key={moduleCode} permissionCodes={modulePermissionCodes}>
							<SidebarItemWrapper className={clsx({ hidden: !isShown })}>
								{!standaloneModule && (moduleCode !== 'brochure' || pathnameArray[0] === ModuleCodeEnum.BROCHURE) && (
									<Sidebar.Item
										icon={isModuleSelected ? <Icon path={mdiChevronLeft} /> : moduleIcon}
										onClick={() => navigateToRoute({ route: moduleRoute, subItems: moduleSubItems })}
										label={moduleName}
									/>
								)}

								<RoutesWrapper className={clsx({ shown: isModuleSelected })}>
									{moduleSubItems?.map(
										({
											icon: pageIcon,
											name: pageName,
											route: pageRoute,
											permissionCodes: pagePermissionCodes = [],
											subItems: pageSubItems,
										}) => {
											const fullPageRoute = `${moduleRoute}${pageRoute}`;

											return (
												<PermissionWall key={fullPageRoute} permissionCodes={pagePermissionCodes}>
													<Sidebar.Item
														active={pathname.startsWith(fullPageRoute)}
														className={clsx('routeItem', { hidden: !isModuleSelected })}
														icon={pageIcon}
														onClick={() => navigateToRoute({ route: fullPageRoute, subItems: pageSubItems })}
														subItems={pageSubItems?.flatMap(
															({
																name: sectionName,
																icon: sectionIcon,
																route: sectionRoute,
																permissionCodes: sectionPermissionCodes,
															}) => {
																const fullSectionRoute = `${fullPageRoute}${sectionRoute}`;

																return sectionPermissionCodes?.some((permissionCode) =>
																	checkPermission(permissionCode),
																) ?? true
																	? [
																			{
																				id: fullSectionRoute,
																				label: sectionName,
																				icon: sectionIcon,
																				name: sectionName,
																				active: pathname.startsWith(fullSectionRoute),
																				onClick: () => navigateToRoute({ route: fullSectionRoute }),
																			},
																	  ]
																	: [];
															},
														)}
														label={pageName}
													/>
												</PermissionWall>
											);
										},
									)}
								</RoutesWrapper>
							</SidebarItemWrapper>
						</PermissionWall>
					);
				},
			)}
		</Sidebar.Body>
	);
};

export default SidebarBody;
