import { getDocumentId } from './processDocuments';
import { DocumentMapperType, DocumentsErrors, SignDocumentType, FieldError } from '../../../types/document';
import {
	DocumentWithErrorType,
	SignMutationErrorEnum,
	SignMutationErrorMessageEnum,
	SignerWithErrorType,
} from '../../../types/procedure';
import { SignerType } from '../../../types/signer';

const SIGNER_INVALID_ERROR_MESSAGE =
	'Veuillez supprimer ce signataire, certaines informations le concernant sont invalides.';

export const updateSignerIdsInDocumentsFields = (
	documents?: SignDocumentType[],
	signers?: SignerType[],
	updatedSigners?: SignerType[],
) => {
	return documents?.map?.(({ fields, ...restDoc }) => ({
		...restDoc,
		fields: fields?.map((field) => ({
			...field,
			signerId: updatedSigners?.find(({ email }) => email === signers?.find(({ id }) => id === field.signerId)?.email)
				?.id,
		})),
	}));
};

export const formatSignersWithErrors = (
	signers?: SignerWithErrorType[],
	signersErrors?: { id?: string; message: string }[],
): SignerWithErrorType[] | undefined => {
	return signers?.map?.((signer) => {
		const error = signersErrors?.find(({ id }) => id === signer.id);
		return { ...signer, hasError: !!error, message: error?.message };
	});
};

const findDocumentByYousignId = (documentMapper?: DocumentMapperType[], yousignId?: DocumentWithErrorType['id']) =>
	documentMapper?.find((mapper) => mapper.yousignId === yousignId);

const buildDocumentErrorMessage = (fieldErrors?: FieldError[]) => {
	const errors = fieldErrors?.map(({ reason }) => reason);

	if (!errors) return undefined;

	const hasSignersErrors = errors.includes('SIGNER_INVALID');
	const hasFieldsErrors = errors.includes('FIELD_INVALID');

	if (hasSignersErrors && hasFieldsErrors) {
		return SignMutationErrorMessageEnum[SignMutationErrorEnum.SIGNER_AND_FIELD_INVALIDS];
	}
	if (hasSignersErrors) {
		return SignMutationErrorMessageEnum[SignMutationErrorEnum.SIGNER_INVALID];
	}
	if (hasFieldsErrors) {
		return SignMutationErrorMessageEnum[SignMutationErrorEnum.FIELD_INVALID];
	}

	return undefined;
};

export const formatSignersAndDocumentsWithErrors = (
	oldDocuments?: DocumentWithErrorType[],
	errors?: DocumentsErrors,
	succeededDocumentsMapper?: DocumentMapperType[],
): {
	documents?: DocumentWithErrorType[];
	signersErrors?: { id?: string; message: string }[];
} => {
	if (!oldDocuments) return { documents: undefined };

	const signersErrors = errors?.partialData
		?.flatMap(({ fieldErrors, documentYousignId }) =>
			fieldErrors?.map((fieldError) => ({ ...fieldError, documentYousignId })),
		)
		.filter((fieldError) => fieldError?.reason === 'SIGNER_INVALID')
		.map((signerFieldError) => ({
			message: SIGNER_INVALID_ERROR_MESSAGE,
			id:
				signerFieldError?.index !== undefined
					? oldDocuments?.find(
							({ id }) =>
								id === signerFieldError?.documentYousignId ||
								id === findDocumentByYousignId(succeededDocumentsMapper, signerFieldError.documentYousignId)?.id,
					  )?.fields?.[signerFieldError?.index].signerId
					: undefined,
		}));

	const interruptedDocumentIndex = oldDocuments?.findIndex(({ id }) => id === errors?.interruptedDocumentId);

	const formattedDocumentsWithErrors = oldDocuments.map(({ id, fields, ...doc }, documentIndex) => {
		const createdDocumentMapper = succeededDocumentsMapper?.find((mapper) => mapper.id === id);
		const document = errors?.partialData?.find(
			({ documentYousignId: docId }) => id === docId || docId === createdDocumentMapper?.yousignId,
		);

		return {
			...doc,
			id: document?.documentYousignId ?? getDocumentId(id, succeededDocumentsMapper) ?? id,
			fields: fields?.map(({ id: fieldId, ...field }, index) => ({
				...field,
				id: document?.fieldsYousignIds?.[index] ?? createdDocumentMapper?.createdFieldsMapper?.[index] ?? fieldId,
				hasError: document?.fieldErrors?.some(({ index: errorIndex }) => index === errorIndex),
			})),
			hasError: interruptedDocumentIndex !== -1 && documentIndex >= interruptedDocumentIndex,
			message: buildDocumentErrorMessage(document?.fieldErrors),
		};
	});

	return {
		signersErrors,
		documents: formattedDocumentsWithErrors,
	};
};
