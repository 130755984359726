import { ApolloError, ServerError } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

const graphQLErrorCodeMap: { [key: string]: number } = {
	GRAPHQL_PARSE_FAILED: 400,
	GRAPHQL_VALIDATION_FAILED: 400,
	BAD_USER_INPUT: 400,
	UNAUTHENTICATED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	PERSISTED_QUERY_NOT_FOUND: 404,
	PERSISTED_QUERY_NOT_SUPPORTED: 405,
	CONFLICT: 409,
	INTERNAL_SERVER_ERROR: 500,
};

const httpCodeMessageMap: { [key: number]: string } = {
	400: 'Erreur de syntaxe dans la requête',
	401: 'Vous devez être connecté pour accéder au contenu',
	403: "Vous n'avez pas les droits pour accéder à cette ressource",
	404: "La ressource demandée n'existe pas",
	500: 'Une erreur est survenue, veuillez contacter le support',
	503: 'Le service est temporairement indisponible, veuillez réeessayer plus tard',
};

const getErrorCodeFromGraphQLError = ({ extensions }: GraphQLError) => {
	const { code } = extensions as { code: string };

	return graphQLErrorCodeMap[code] ?? +code;
};

const getErrorCodeNetworkError = (error: NetworkError) => {
	const { statusCode } = error as { statusCode: number };

	return statusCode;
};

const getMessageFromHttpCode = (code: number) => httpCodeMessageMap[code] ?? httpCodeMessageMap[500];

// TODO: Remove the stacktrace
const getErrorDetails = ({
	graphQLErrors,
	networkError,
}: ApolloError): Omit<ErrorType['details'], 'resource'> | null => {
	if (graphQLErrors.length > 0) {
		return graphQLErrors[0];
	}

	if (networkError) {
		return networkError;
	}

	return null;
};

type ResourceType = NonNullable<ErrorType['details']>['resource'];

const generateErrorInformations = ({ error, resource }: { error: ApolloError; resource: ResourceType }) => {
	const { graphQLErrors, networkError } = error;

	let code = 0;

	graphQLErrors.length > 0 && (code = getErrorCodeFromGraphQLError(graphQLErrors[0]));
	networkError && (code = getErrorCodeNetworkError(networkError));

	const message = getMessageFromHttpCode(code);
	const details = getErrorDetails(error);

	return {
		code,
		message,
		details: {
			resource,
			...details,
		} as ErrorType['details'],
	};
};

export type ErrorType = {
	code: number;
	message: string;
	details?: (GraphQLError | NetworkError | ServerError | Error) & { resource: string };
};

export { generateErrorInformations };
