import { useMutation } from '@apollo/client';
import { ButtonSwitch, Dialog, DialogProps, ListInstance, TextField } from '@elipssolution/harfang';
import { Stack, styled, Typography } from '@mui/material';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';

import 'react-phone-input-2/lib/material.css';

import { DIALOG_CLOSE_DELAY } from '../../../src/utils/dialogCloseDelay';
import { emailPattern } from '../../../utils/emailPattern';
import {
	CREATE_CONTACT,
	CreateContactMutationVariablesType,
	CreateContactType,
	UPDATE_CONTACT,
	UpdateContactMutationVariablesType,
	UpdateContactType,
} from '../api/contact';
import { ContactFormType, SignContactType } from '../types/contact';

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
	'& .MuiDialogContent-root': {
		display: 'flex',
		flexDirection: 'column',
		gap: spacing(3),
	},
}));

const StyledPhoneInput = styled(PhoneInput)(({ theme: { palette, shadows, shape, typography, spacing }, isValid }) => ({
	fontSize: typography.fontSize,

	'&:focus-within div.special-label': {
		color: isValid ? palette.primary.main : palette.error.main,
	},

	'input.form-control': {
		padding: spacing(1.25, 0, 1.25, 7), // padding-left is to make space for the flag icon
		width: '100%',
		borderRadius: shape.borderRadius * 2,
		fontSize: typography.caption,
		borderColor: '#c4c4c4',

		'&:focus': {
			borderColor: isValid ? palette.primary.main : palette.error.main,
			boxShadow: isValid ? `0 0 0 1px ${palette.primary.main}` : `0 0 0 1px ${palette.error.main}`,
		},

		'&.invalid-number': {
			borderColor: palette.error.main,
		},
	},

	'div.special-label': {
		color: isValid ? 'rgba(0, 0, 0, 0.6)' : palette.error.main,
		left: 7,
		top: -8,
	},

	'ul.country-list': {
		maxHeight: 170,
		bottom: 0,
		boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.25)',

		'li.search': {
			padding: spacing(1),

			input: {
				width: '100%',

				margin: 0,
				padding: spacing(1.06, 1.75),

				borderRadius: shape.borderRadius * 2,

				fontSize: 16,
			},

			boxShadow: shadows[1],
		},

		'li:not(.search)': {
			padding: spacing(1.5, 1),
			display: 'flex',
			alignItems: 'center',
			gap: spacing(1),

			'div.flag': {
				position: 'relative',
				top: -2,
				left: 0,
				margin: 0,

				width: 23,
			},

			span: {
				'&.country-name': {
					margin: 0,
				},
			},
		},
	},
}));
const INTERNATIONAL_PHONE_PREFIX_REGEX = /^\+\d{1,4}$/;

const ErrorInfoTypography = styled((props) => <Typography {...props} variant="caption" color="error" />)(
	({ theme: { spacing } }) => ({
		margin: spacing(0.5, 1.75, 0, 1.75),
	}),
);

const Row = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(3),
}));

const ButtonSwitchWrapper = styled('div')({
	maxWidth: 250,
});

const privacyOptions = [
	{ id: 'isPublic', label: 'Public' },
	{ id: 'isPrivate', label: 'Privé' },
];

const defaultValues = {
	isPrivate: false,
	lastName: '',
	firstName: '',
	email: '',
	phoneNumber: '',
};

let emailTimeout: ReturnType<typeof setTimeout>;
const emailErrorMessage = 'Adresse mail invalide. Merci de vérifier son format.';

let phoneNumberTimeout: ReturnType<typeof setTimeout>;
const phoneNumberErrorMessage = 'Numéro de téléphone invalide. Exemple de format attendu : +33 7 10 20 03 04';

const phoneUtil = PhoneNumberUtil.getInstance();

type ContactDialogProps = {
	isOpen: boolean;
	onClose: (params?: { refreshTable?: boolean }) => void;
	existingContact?: SignContactType;
	listInstance?: ListInstance;
};

const ContactDialog = ({ isOpen, onClose, existingContact, listInstance }: ContactDialogProps) => {
	const {
		control,
		formState: { isValid, errors, isDirty },
		reset,
		handleSubmit,
		setError,
		watch,
	} = useForm<ContactFormType>({
		defaultValues,
		mode: 'onChange',
	});
	const phoneNumberFormValue = watch('phoneNumber');

	const [hasSucceeded, setHasSucceeded] = useState(false);

	const handleCloseWithRefresh = useCallback(() => {
		onClose({ refreshTable: true });
		reset(defaultValues);
		setHasSucceeded(false);
	}, [onClose, reset]);

	const [createContactMutation, { loading: isCreateLoading, error: createError, reset: resetCreateMutation }] =
		useMutation<CreateContactType, CreateContactMutationVariablesType>(CREATE_CONTACT, {
			onCompleted: () => {
				setHasSucceeded(true);
				setTimeout(() => {
					handleCloseWithRefresh();
					resetCreateMutation();
				}, DIALOG_CLOSE_DELAY);
				listInstance?.reload();
			},
		});

	const [updateContactMutation, { loading: isUpdateLoading, error: updateError, reset: resetUpdateMutation }] =
		useMutation<UpdateContactType, UpdateContactMutationVariablesType>(UPDATE_CONTACT, {
			onCompleted: () => {
				setHasSucceeded(true);
				setTimeout(() => {
					handleCloseWithRefresh();
					resetUpdateMutation();
				}, DIALOG_CLOSE_DELAY);
			},
		});

	const handleCancelation = () => {
		onClose();
		reset(defaultValues);
		resetCreateMutation();
		resetUpdateMutation();
	};

	const createContact = useCallback(
		(contact: ContactFormType) =>
			createContactMutation({
				variables: {
					createContactInput: contact,
				},
			}),
		[createContactMutation],
	);

	const updateContact = useCallback(
		(contact: UpdateContactMutationVariablesType['updateContactInput']) => {
			if (!existingContact) {
				throw Error('An existing contact should have already been declared before opening the update contact dialog.');
			}

			return updateContactMutation({
				variables: {
					updateContactInput: contact,
				},
			});
		},
		[existingContact, updateContactMutation],
	);

	const onSubmit = useCallback(
		(contact: ContactFormType) =>
			existingContact
				? updateContact({
						...contact,
						id: existingContact.id,
				  })
				: createContact(contact),
		[createContact, existingContact, updateContact],
	);

	const validateEmail = useCallback((email?: ContactFormType['email']): true | string => {
		if (!email) {
			return emailErrorMessage;
		}

		const isInvalidEmail = !emailPattern.test(email);

		if (isInvalidEmail) {
			return emailErrorMessage;
		}

		return true;
	}, []);

	const debouncedEmailValidation = useCallback(
		(email?: ContactFormType['email']): Promise<true | string> => {
			clearTimeout(emailTimeout);

			return new Promise<true | string>((resolve) => {
				emailTimeout = setTimeout(() => {
					const validityResult = validateEmail(email);
					resolve(validityResult);
				}, 300);
			});
		},
		[validateEmail],
	);

	const validatePhoneNumber = useCallback((phoneNumber?: ContactFormType['phoneNumber']): true | string => {
		if (!phoneNumber) {
			return phoneNumberErrorMessage;
		}

		if (INTERNATIONAL_PHONE_PREFIX_REGEX.test(phoneNumber)) {
			return true;
		}

		try {
			const parsedNumber = phoneUtil.parse(phoneNumber);

			return phoneUtil.isValidNumber(parsedNumber) || phoneNumberErrorMessage;
		} catch (error) {
			return phoneNumberErrorMessage;
		}
	}, []);

	const debouncedPhoneNumberValidation = useCallback(
		(phoneNumber?: ContactFormType['phoneNumber']): Promise<true | string> => {
			clearTimeout(phoneNumberTimeout);

			return new Promise<true | string>((resolve) => {
				phoneNumberTimeout = setTimeout(() => resolve(validatePhoneNumber(phoneNumber)), 300);
			});
		},
		[validatePhoneNumber],
	);

	useEffect(() => {
		if (existingContact) {
			const { firstName, lastName, email, phoneNumber, isPrivate } = existingContact;

			reset({
				firstName,
				lastName,
				email,
				phoneNumber,
				isPrivate,
			});

			const emailValidity = validateEmail(existingContact.email);
			if (emailValidity !== true) {
				setError('email', { message: emailValidity });
			}

			const phoneNumberValidity = validatePhoneNumber(existingContact.phoneNumber);
			if (phoneNumberValidity !== true) {
				setError('phoneNumber', { message: phoneNumberValidity });
			}
		}
	}, [existingContact, reset, setError, validateEmail, validatePhoneNumber]);

	useEffect(() => {
		if (isDirty) {
			resetCreateMutation();
			resetUpdateMutation();
		}
	}, [isDirty, resetCreateMutation, resetUpdateMutation]);

	const hasInputErrors = Object.keys(errors).length > 0;

	const submitButtonLabel = useMemo(() => {
		if (hasSucceeded && !existingContact) {
			return 'Contact créé';
		}

		if (hasSucceeded) {
			return 'Contact modifié';
		}

		if (existingContact) {
			return 'Modifier le contact';
		}

		return 'Créer le contact';
	}, [hasSucceeded, existingContact]);

	const actionsDialog: DialogProps['actionsDialog'] = [
		{
			label: 'Annuler',
			onClick: handleCancelation,
		},
		{
			label: submitButtonLabel,
			onClick: handleSubmit(onSubmit),
			variant: 'contained',
			disabled: INTERNATIONAL_PHONE_PREFIX_REGEX.test(phoneNumberFormValue) || !isValid || hasInputErrors,
			loading: isCreateLoading || isUpdateLoading,
			success: hasSucceeded,
			error: !!createError || !!updateError,
			persistantErrorMessage: createError?.message || updateError?.message,
		},
	];

	return (
		<StyledDialog
			fullWidth
			actionsDialog={actionsDialog}
			title={existingContact ? "Modification d'un contact" : "Création d'un contact"}
			open={isOpen}
			onClose={handleCancelation}
		>
			<ButtonSwitchWrapper>
				<Controller
					name="isPrivate"
					control={control}
					render={({ field: { value, onChange } }) => (
						<ButtonSwitch
							selectedItem={value ? 'isPrivate' : 'isPublic'}
							items={privacyOptions}
							onClick={(id) => onChange(id === 'isPrivate')}
						/>
					)}
				/>
			</ButtonSwitchWrapper>

			<Row>
				<Controller
					name="lastName"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, ...field } }) => {
						const handleUpperCaseChange = (newLastName?: string) => onChange(newLastName?.toUpperCase());

						return <TextField {...field} onChange={handleUpperCaseChange} label="Nom" required />;
					}}
				/>
				<Controller
					name="firstName"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, ...field } }) => {
						const handleFirstLetterUpperCaseChange = (newFirstName?: string) =>
							onChange(newFirstName ? newFirstName.charAt(0).toUpperCase() + newFirstName.slice(1) : undefined);

						return <TextField {...field} onChange={handleFirstLetterUpperCaseChange} label="Prénom" required />;
					}}
				/>
			</Row>

			<Row>
				<Controller
					name="email"
					control={control}
					rules={{
						required: 'Adresse mail requise',
						validate: debouncedEmailValidation,
					}}
					render={({ field, fieldState: { error } }) => (
						<TextField {...field} required label="Adresse mail" invalid={!!error} helperText={error?.message} />
					)}
				/>

				<Stack width="100%">
					<Controller
						name="phoneNumber"
						control={control}
						rules={{
							required: 'Numéro de téléphone requis',
							validate: debouncedPhoneNumberValidation,
						}}
						render={({ field: { onChange, ...field }, fieldState: { error } }) => (
							<>
								<StyledPhoneInput
									{...field}
									country="fr"
									isValid={!error}
									localization={fr}
									onChange={(value, country, event, formattedValue) => onChange(formattedValue)}
									searchNotFound="Aucun résultat"
									searchPlaceholder="Rechercher"
									specialLabel="Téléphone *"
									enableSearch
								/>

								{error && <ErrorInfoTypography>{error?.message}</ErrorInfoTypography>}
							</>
						)}
					/>
				</Stack>
			</Row>
		</StyledDialog>
	);
};

export default ContactDialog;
