import { Stack } from '@mui/material';

import CanceledProceduresTable from './CanceledProceduresTable';
import ProceduresTableHeader from '../../../components/ProceduresTableHeader';

const Canceled = () => (
	<Stack height="100%" gap={2}>
		<ProceduresTableHeader />

		<CanceledProceduresTable />
	</Stack>
);

export default Canceled;
