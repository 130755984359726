import { ProcedureDocumentType, SignDocumentType, YousignServiceUnavailable } from './document';
import { SignerType } from './signer';

export enum ProcedureStatusEnum {
	DRAFT = 'DRAFT',
	ONGOING = 'ONGOING',
	DECLINED = 'DECLINED',
	EXPIRED = 'EXPIRED',
	CANCELED = 'CANCELED',
	DONE = 'DONE',
}

export type UserType = {
	id: string;
	firstName?: string;
	lastName: string;
};

export type ProcedureType = {
	id: string;
	name: string;
	isPrivate: boolean;
	status: ProcedureStatusEnum;
	createdBy: UserType;
	updatedAt: Date;
	signers: SignerType[];
	comment?: string;
	documents: ProcedureDocumentType[];
	hasError?: boolean;
};

export type ProcedureInputType = {
	id: ProcedureType['id'];
	global: SaveProcedureInput;
	signers?: SaveSignersInput;
	documents?: DocumentWithErrorType[];
};

export type SaveProcedureInput = {
	name: ProcedureType['name'];
	isPrivate?: ProcedureType['isPrivate'];
	comment?: string;
};

export type SaveSignersInput = {
	isSignOrdered: boolean;
	signers?: SignerWithErrorType[];
};
export type SignerWithErrorType = SignerType & { message?: string; hasError?: boolean };
export type DocumentWithErrorType = SignDocumentType & { message?: string; hasError?: boolean };

export type ProcedureFormType = Omit<ProcedureInputType, 'id'>;

export type CreateProcedureError = {
	reason: 'SERVICE_UNAVAILABLE' | 'PROCEDURE_NAME_INVALID';
};

export type UpdateProcedureError = YousignServiceUnavailable & {
	isProcedureNameInvalid?: boolean;
	partialData?: { signers: SignerType[] };
};

export enum SignMutationErrorEnum {
	YOUSIGN_UNAVAILABLE = 'YOUSIGN_UNAVAILABLE',
	PROCEDURE_NAME_INVALID = 'PROCEDURE_NAME_INVALID',
	FIELD_INVALID = 'FIELD_INVALID',
	SIGNER_INVALID = 'SIGNER_INVALID',
	SIGNER_AND_FIELD_INVALIDS = 'SIGNER_AND_FIELD_INVALIDS',
}
export enum SignMutationErrorMessageEnum {
	YOUSIGN_UNAVAILABLE = 'Service temporairement indisponible. Merci de réessayer plus tard.',
	PROCEDURE_NAME_INVALID = 'Nom de procédure invalide',
	FIELD_INVALID = 'Veuillez vérifier les dimensions et le contenu des emplacements.',
	SIGNER_INVALID = 'Veuillez supprimer les emplacements liés aux signataires en erreur.',
	SIGNER_AND_FIELD_INVALIDS = 'Veuillez supprimer les emplacements liés aux signataires en erreur et vérifier les dimensions et le contenu des emplacements.',
}
