import { saveAs } from 'file-saver';

import { CustomerFileWithDomainType } from '../../types/customerFile';

export const downloadFile = (fileUrl: string, fileName: string) => saveAs(fileUrl, fileName);

export const getFileBlob = async (
	url: string,
	accessToken?: string,
	selectedCustomerFileId?: CustomerFileWithDomainType['id'],
): Promise<Blob> => {
	const headers = new Headers();
	if (accessToken) {
		headers.append('Authorization', `Bearer ${accessToken}`);
	}

	if (selectedCustomerFileId) {
		headers.append('customer-file-id', selectedCustomerFileId);
	}

	const result = await fetch(url, {
		headers,
		method: 'GET',
	});

	if (!result.ok) {
		const message = await result.text();

		if (message.includes("The document isn't anymore into the storage upload.")) {
			throw new Error('Le document a déjà été synchronisé.');
		}

		throw new Error('Erreur lors de la récupération du fichier.');
	}

	const documentBlob = await result.blob();

	return documentBlob;
};

export const deleteFile = async (
	url: string,
	accessToken?: string,
	selectedCustomerFileId?: CustomerFileWithDomainType['id'],
): Promise<boolean> => {
	const headers = new Headers();
	if (accessToken) {
		headers.append('Authorization', `Bearer ${accessToken}`);
	}

	if (selectedCustomerFileId) {
		headers.append('customer-file-id', selectedCustomerFileId);
	}

	const result = await fetch(url, {
		headers,
		method: 'DELETE',
	});

	if (!result.ok) {
		throw new Error('Erreur lors de la suppression du fichier.');
	}

	return result.ok;
};

export const uploadFile = async (
	accessToken: string,
	file: File,
	selectedCustomerFileId: CustomerFileWithDomainType['id'],
	uri: string,
): Promise<void> => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('customer-file-id', selectedCustomerFileId);

	const response = await fetch(uri, {
		method: 'POST',
		headers,
		body: file,
	});

	if (!response.ok) {
		throw new Error('Unable to upload file');
	}
};

export const uploadFileWithFormData = async (
	accessToken: string,
	file: File,
	selectedCustomerFileId: CustomerFileWithDomainType['id'],
	uri: string,
): Promise<void> => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('customer-file-id', selectedCustomerFileId);

	const body = new FormData();
	body.append('file', file);

	const response = await fetch(uri, {
		method: 'POST',
		headers,
		body,
	});

	if (!response.ok) {
		throw new Error('Unable to upload file');
	}
};

export const uploadFileWithFormDataAndReturningErrorResponse = async <T>(
	accessToken: string,
	file: File,
	selectedCustomerFileId: CustomerFileWithDomainType['id'],
	uri: URL,
): Promise<undefined | T> => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('customer-file-id', selectedCustomerFileId);

	const body = new FormData();
	body.append('file', file);

	const response = await fetch(uri, {
		method: 'POST',
		headers,
		body,
	});

	if (!response.ok) {
		return (await response.json().catch(() => {
			throw new Error('Unable to upload file');
		})) as T;
	}

	return undefined;
};
export const uploadFileWithFormDataAndReturningResponse = async <T>({
	accessToken,
	file,
	selectedCustomerFileId,
	uri,
}: {
	accessToken: string;
	file: File;
	selectedCustomerFileId: CustomerFileWithDomainType['id'];
	uri: URL;
}): Promise<undefined | T> => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('customer-file-id', selectedCustomerFileId);

	const body = new FormData();
	body.append('file', file);

	const response = await fetch(uri, {
		method: 'POST',
		headers,
		body,
	});
	if (response.ok) return response.json() as T;
	throw new Error('Unable to upload file');
};

export const uploadFileAndReturnDataOrError = async <T, E>({
	accessToken,
	file,
	selectedCustomerFileId,
	uri,
}: {
	accessToken: string;
	file: File;
	selectedCustomerFileId: CustomerFileWithDomainType['id'];
	uri: URL;
}): Promise<{ data?: T; error?: E }> => {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('customer-file-id', selectedCustomerFileId);

	const body = new FormData();
	body.append('file', file);

	const response = await fetch(uri, {
		method: 'POST',
		headers,
		body,
	});

	if (response.ok) {
		const data = (await response.json()) as T;
		return { data };
	}

	const error = (await response.json()) as E;
	return { error };
};

export const formatFileSize = (fileSize: number) => {
	const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To'];

	if (fileSize === 0) return '0 Octet';

	const i = Math.floor(Math.log(fileSize) / Math.log(1000));
	const formattedSize = (fileSize / 1000 ** i).toFixed(2);

	return `${formattedSize} ${sizes[i]}`;
};

export const openBlobInNewTab = (blob: Blob) => {
	const fileURL = URL.createObjectURL(blob);
	window.open(fileURL, '_blank', 'noopener,noreferrer');
	// Revoke after a bit of time to let the PDF load, and then avoid a potential memory leak
	setTimeout(() => URL.revokeObjectURL(fileURL), 1000);
};
