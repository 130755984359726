import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Button, DialogProps } from '@elipssolution/harfang';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import DialogStepper from './dialogStepper/DialogStepper';
import DocumentsForm from './procedure/documentsForm/DocumentsForm';
import GlobalForm from './procedure/globalForm/GlobalForm';
import SignersForm from './procedure/signersForm/SignersForm';
import {
	formatSignersAndDocumentsWithErrors,
	formatSignersWithErrors,
	updateSignerIdsInDocumentsFields,
} from './procedure/utils/formatProcedureFormData';
import { getDocumentId, findAddedAndUpdatedDocuments } from './procedure/utils/processDocuments';
import { useSession } from '../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../src/utils/dialogCloseDelay';
import { uploadFileAndReturnDataOrError } from '../../../src/utils/file';
import { UPDATE_DOCUMENTS, UpdateDocumentsType } from '../api/document';
import {
	CREATE_PROCEDURE,
	CreateProcedureType,
	FETCH_DRAFT_PROCEDURE,
	FetchDraftProcedureType,
	RESET_PROCEDURE_ERROR_STATUS,
	ResetProcedureErrorStatusType,
	START_PROCEDURE,
	StartProcedureType,
	UPDATE_PROCEDURE,
	UpdateProcedureType,
} from '../api/procedure';
import { SignContactType } from '../types/contact';
import {
	DocumentFieldTypeEnum,
	DocumentMapperType,
	DocumentsErrors,
	SignDocumentType,
	UploadDocumentError,
} from '../types/document';
import {
	DocumentWithErrorType,
	ProcedureFormType,
	ProcedureInputType,
	ProcedureType,
	CreateProcedureError,
	UpdateProcedureError,
	SignMutationErrorEnum,
	SignMutationErrorMessageEnum,
} from '../types/procedure';
import { SignerType } from '../types/signer';

const defaultFormValues = {
	global: {
		name: '',
		comment: '',
		isPrivate: false,
	},
	signers: {
		isSignOrdered: false,
		signers: [],
	},
};

type ProcedureCreationDialogProps = {
	isOpen: boolean;
	procedureId?: ProcedureType['id'];
	onClose: () => void;
};

const ProcedureDialog = ({ isOpen, procedureId, onClose }: ProcedureCreationDialogProps) => {
	const {
		control,
		formState: { isDirty, dirtyFields, isValid, errors },
		handleSubmit,
		reset,
		watch,
		setValue,
		setError,
	} = useForm<ProcedureFormType>();
	const { customerFile: sessionCustomerFile } = useSession();
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};

	const [chosenContactsEmails, setChosenContactsEmails] = useState<SignContactType['email'][]>([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [hasSavingError, setHasSavingError] = useState(false);
	const [hasSavingSucceeded, setHasSavingSucceeded] = useState(false);
	const [hasStartingProcedureError, setHasStartingProcedureError] = useState(false);
	const [hasStartingProcedureSucceeded, setHasStartingProcedureSucceeded] = useState(false);
	const [procedure, setProcedure] = useState<ProcedureInputType & { hasError?: boolean }>();
	const [isTriggeredByStartProcedureButton, setIsTriggeredByStartProcedureButton] = useState(false);
	const [isUploadingDocumentsLoading, setIsUploadingDocumentsLoading] = useState(false);
	const [isServiceUnavailable, setIsServiceUnavailable] = useState(false);

	const signersFormValue = watch('signers.signers');
	const documentsFormValue = watch('documents');

	const stepValidityMapper = useMemo(
		() =>
			new Map([
				[0, isValid],
				[1, signersFormValue && signersFormValue.length > 0],
				[
					2,
					signersFormValue &&
						(documentsFormValue?.length ?? -1) > 0 &&
						documentsFormValue?.every(
							(document) =>
								!!document.fields &&
								document.fields.some(({ type, hasError }) => type === DocumentFieldTypeEnum.SIGNATURE && !hasError),
						) &&
						signersFormValue.every(({ id }) =>
							documentsFormValue.some((document) => document.fields?.some(({ signerId }) => signerId === id)),
						),
				],
			]),
		[documentsFormValue, isValid, signersFormValue],
	);

	const resetStates = () => {
		setHasSavingError(false);
		setHasSavingSucceeded(false);
		setHasStartingProcedureError(false);
		setHasStartingProcedureSucceeded(false);
		setChosenContactsEmails([]);
		setProcedure(undefined);
		setIsUploadingDocumentsLoading(false);
		setIsServiceUnavailable(false);
	};

	const handleClose = useCallback(() => {
		reset(defaultFormValues);
		setCurrentStep(0);
		resetStates();
		reset();
		onClose();
	}, [onClose, reset]);

	const showSuccess = useCallback(
		(isTriggeredByStartProcedure = false) => {
			if (isTriggeredByStartProcedure) {
				setHasStartingProcedureSucceeded(true);
				setTimeout(() => handleClose(), DIALOG_CLOSE_DELAY);
			} else {
				setHasSavingSucceeded(true);
				setTimeout(() => setHasSavingSucceeded(false), DIALOG_CLOSE_DELAY);
			}
		},
		[handleClose],
	);

	const showError = useCallback((isTriggeredByStartProcedure = false) => {
		if (isTriggeredByStartProcedure) {
			setHasStartingProcedureError(true);
			setTimeout(() => setHasStartingProcedureError(false), DIALOG_CLOSE_DELAY);
		} else {
			setHasSavingError(true);
			setTimeout(() => setHasSavingError(false), DIALOG_CLOSE_DELAY);
		}
	}, []);

	const uploadAllDocuments = useCallback(
		({
			id,
			documentsToUpload,
		}: {
			id: ProcedureType['id'];
			documentsToUpload?: SignDocumentType[];
		}): Promise<{ data: DocumentMapperType[]; error?: DocumentsErrors }> => {
			if (!access_token || !sessionCustomerFile?.id || !documentsToUpload || documentsToUpload.length === 0) {
				return Promise.resolve({ data: [] });
			}
			let uploadDocumentsErrors: DocumentsErrors;
			const partialData: DocumentsErrors['partialData'] = [];

			const documentsIdsMapper: DocumentMapperType[] = [];
			setIsUploadingDocumentsLoading(true);

			return documentsToUpload
				.reduce<Promise<{ previousDocumentId?: string; isUploadServiceAvailable?: boolean }>>(
					async (previousPromise, currentDocument) => {
						const { previousDocumentId, isUploadServiceAvailable } = await previousPromise;

						const uri = new URL(`/sign/documents`, window.location.href);
						uri.searchParams.append('procedureId', id);
						if (previousDocumentId) {
							uri.searchParams.append('afterId', previousDocumentId);
						}
						if (currentDocument.fields) {
							uri.searchParams.append('fields', JSON.stringify(currentDocument.fields));
						}

						let documentMapperData;
						if (currentDocument.document && isUploadServiceAvailable) {
							const response = await uploadFileAndReturnDataOrError<
								{
									documentId: string;
									createdFieldsMapper: string[];
								},
								UploadDocumentError
							>({
								accessToken: access_token,
								file: currentDocument.document,
								uri,
								selectedCustomerFileId: sessionCustomerFile.id,
							});
							documentMapperData = response.data;
							const documentMapperErrors = response.error;
							documentsIdsMapper.push({
								id: currentDocument.id,
								yousignId: documentMapperData?.documentId ?? documentMapperErrors?.partialData?.documentYousignId,
								createdFieldsMapper: documentMapperData?.createdFieldsMapper,
							});

							if (documentMapperErrors) {
								uploadDocumentsErrors = {
									...uploadDocumentsErrors,
									isYousignServiceUnavailable: documentMapperErrors?.isYousignServiceUnavailable,
									interruptedDocumentId: documentMapperErrors?.isYousignServiceUnavailable
										? currentDocument.id
										: undefined,
									partialData: [
										...partialData,
										{
											documentYousignId: documentMapperErrors.partialData?.documentYousignId ?? currentDocument.id,
											fieldsYousignIds: documentMapperErrors.partialData?.fieldsYousignIds ?? [],
											fieldErrors: documentMapperErrors.partialData?.fieldErrors,
										},
									],
								};
								return {
									isUploadServiceAvailable: !documentMapperErrors?.isYousignServiceUnavailable,
								};
							}
						}
						return { previousDocumentId: documentMapperData?.documentId, isUploadServiceAvailable };
					},
					Promise.resolve({ previousDocumentId: undefined, isUploadServiceAvailable: true }),
				)
				.then(() => {
					setIsUploadingDocumentsLoading(false);
					return { data: documentsIdsMapper, error: uploadDocumentsErrors };
				});
		},
		[access_token, sessionCustomerFile?.id],
	);

	useQuery<FetchDraftProcedureType>(FETCH_DRAFT_PROCEDURE, {
		variables: { signProcedureId: procedureId },
		skip: !procedureId,
		onCompleted: ({ sign_procedure }) => {
			setChosenContactsEmails(sign_procedure?.signers.map(({ email }) => email));
			const procedureToEdit = {
				id: sign_procedure.id,
				global: {
					name: sign_procedure?.name,
					isPrivate: sign_procedure?.isPrivate,
					comment: sign_procedure?.comment,
				},
				signers: {
					isSignOrdered: sign_procedure.signers.length === 0 ? false : sign_procedure.signers?.[0]?.rank !== null,
					signers: sign_procedure?.signers.map(({ id, firstName, lastName, email }) => ({
						id,
						firstName,
						lastName,
						email,
					})),
				},
				hasError: sign_procedure.hasError,
				documents: (sign_procedure?.documents ?? []).map(({ id, filename, fields }) => ({
					id,
					filename,
					fields: fields.map(({ id: fieldId, page, x, y, width, height, text, mention, type, signer }) => ({
						id: fieldId,
						page,
						x,
						y,
						mention,
						width,
						height,
						text,
						type,
						signerId: signer?.id,
					})),
				})),
			};
			reset(procedureToEdit);
			setProcedure(procedureToEdit);
		},
	});

	const [createProcedureMutation, { loading: isProcedureCreationLoading }] =
		useMutation<CreateProcedureType>(CREATE_PROCEDURE);
	const [updateProcedureMutation, { loading: isProcedureUpdatingLoading }] =
		useMutation<UpdateProcedureType>(UPDATE_PROCEDURE);
	const [startProcedureMutation, { loading: isProcedureStartingLoading }] =
		useMutation<StartProcedureType>(START_PROCEDURE);

	const [resetProcedureErrorStatus] = useMutation<ResetProcedureErrorStatusType>(RESET_PROCEDURE_ERROR_STATUS);

	const [updateDocumentsMutation, { loading: isUpdatingDocumentsLoading }] =
		useMutation<UpdateDocumentsType>(UPDATE_DOCUMENTS);

	const updateDocuments = useCallback(
		async (
			newDocuments: ProcedureFormType['documents'],
		): Promise<{
			documents?: DocumentWithErrorType[];
			signersErrors?: { id?: string; message: string; documentId?: string }[];
			hasErrors?: boolean;
		}> => {
			if (!procedure?.id || !newDocuments) return { documents: undefined };

			const initialDocuments = procedure?.documents ?? [];

			const { documentsToCreate, documentsToUpdate } = findAddedAndUpdatedDocuments(newDocuments, initialDocuments);
			const documentsToRemove = initialDocuments
				.filter(
					({ id: initialDocumentId }) =>
						!newDocuments.some(({ id: formDocumentId }) => formDocumentId === initialDocumentId),
				)
				.map(({ id: docId }) => docId);

			const { data: uploadDocumentsData, error: uploadDocumentsErrors } = await uploadAllDocuments({
				id: procedure?.id,
				documentsToUpload: documentsToCreate,
			});
			// Check if Yousign is unavailable.
			if (uploadDocumentsErrors?.isYousignServiceUnavailable) {
				setIsServiceUnavailable(true);
				const { documents: formattedDocuments, signersErrors } = formatSignersAndDocumentsWithErrors(
					newDocuments,
					uploadDocumentsErrors,
				);
				return { documents: formattedDocuments, signersErrors, hasErrors: true };
			}
			// If no blocking error, we can proceed to the update.
			if (documentsToUpdate.length > 0 || documentsToRemove.length > 0) {
				try {
					const { data: sign_updateDocuments } = await updateDocumentsMutation({
						variables: {
							saveDocumentsInput: {
								procedureId: procedure?.id,
								updatedDocuments: documentsToUpdate.map(({ id, afterId, ...document }) => ({
									...document,
									id: getDocumentId(id, uploadDocumentsData),
									afterId: afterId ? getDocumentId(afterId, uploadDocumentsData) : undefined,
								})),
								removedDocuments: documentsToRemove,
							},
						},
					});

					sign_updateDocuments?.sign_updateDocuments.yousignDocuments?.map(({ fieldsYousignIds, documentYousignId }) =>
						uploadDocumentsData.push({
							id: documentYousignId as DocumentMapperType['id'],
							yousignId: documentYousignId,
							createdFieldsMapper: fieldsYousignIds,
						}),
					);
				} catch (error) {
					const updateDocumentsErrors = (error as ApolloError).graphQLErrors[0].extensions as DocumentsErrors;
					if (updateDocumentsErrors.isYousignServiceUnavailable) setIsServiceUnavailable(true);

					// A new documentsErrors object must be created that contains potential upload errors + update errors.
					const documentsErrors = {
						interruptedDocumentId: updateDocumentsErrors.interruptedDocumentId,
						partialData: [
							...(updateDocumentsErrors?.partialData ? [...updateDocumentsErrors.partialData] : []),
							...(uploadDocumentsErrors?.partialData ? [...uploadDocumentsErrors.partialData] : []),
						],
					};

					const { documents: formattedDocuments, signersErrors } = formatSignersAndDocumentsWithErrors(
						newDocuments,
						documentsErrors,
					);

					return { documents: formattedDocuments, signersErrors, hasErrors: true };
				}
			}

			const { documents: formattedDocuments, signersErrors } = formatSignersAndDocumentsWithErrors(
				newDocuments,
				uploadDocumentsErrors,
				uploadDocumentsData,
			);

			return { documents: formattedDocuments, signersErrors, hasErrors: !!uploadDocumentsErrors };
		},
		[procedure?.documents, procedure?.id, updateDocumentsMutation, uploadAllDocuments],
	);

	const saveProcedureCreation = useCallback(
		async ({
			global,
			signers,
			documents,
		}: ProcedureFormType): Promise<
			Omit<ProcedureInputType, 'id'> & { id?: ProcedureInputType['id']; hasErrors?: boolean }
		> => {
			let createdProcedureData: CreateProcedureType | null | undefined = null;
			try {
				createdProcedureData = (
					await createProcedureMutation({
						variables: {
							createProcedureInput: {
								global,
								signers: {
									...signers,
									signers: (signers?.signers ?? []).map(({ id }) => id),
								},
							},
						},
					})
				)?.data;
			} catch (error) {
				if (
					((error as ApolloError).graphQLErrors[0].extensions as CreateProcedureError).reason === 'SERVICE_UNAVAILABLE'
				)
					setIsServiceUnavailable(true);
				else
					setError('global.name', {
						message: SignMutationErrorMessageEnum[SignMutationErrorEnum.PROCEDURE_NAME_INVALID],
					});

				return {
					global,
					signers: {
						isSignOrdered: signers?.isSignOrdered ?? false,
						signers: signers?.signers,
					},
					documents,
					hasErrors: true,
				};
			}

			const { sign_createProcedure } = createdProcedureData ?? {};
			const { id: createdProcedureId, signers: createdSigners } = sign_createProcedure ?? {};

			if (!createdProcedureId) throw new Error('An error occurred while creating the procedure.');

			// Update signer ids in documents Fields
			const documentsToUpload = updateSignerIdsInDocumentsFields(documents, signers?.signers, createdSigners);

			const { data: uploadDocumentsData, error: uploadDocumentsErrors } = await uploadAllDocuments({
				id: createdProcedureId,
				documentsToUpload,
			});

			if (uploadDocumentsErrors?.isYousignServiceUnavailable) setIsServiceUnavailable(true);
			const { documents: formattedDocuments, signersErrors } = formatSignersAndDocumentsWithErrors(
				documentsToUpload,
				uploadDocumentsErrors,
				uploadDocumentsData,
			);

			// Format signers with errors
			return {
				id: createdProcedureId,
				global,
				signers: {
					isSignOrdered: signers?.isSignOrdered ?? false,
					signers: formatSignersWithErrors(createdSigners, signersErrors),
				},
				documents: formattedDocuments,
				hasErrors: !!uploadDocumentsErrors,
			};
		},
		[createProcedureMutation, setError, uploadAllDocuments],
	);

	const saveProcedureUpdate = useCallback(
		async ({
			global,
			signers,
			documents,
		}: ProcedureFormType): Promise<ProcedureInputType & { hasErrors?: boolean }> => {
			if (!procedure?.id) throw new Error('Procedure ID is missing');
			let updatedSigners: SignerType[] = [];
			let hasInvalidName = false;

			if (dirtyFields.global || dirtyFields.signers) {
				try {
					const updatedProcedure = await updateProcedureMutation({
						variables: {
							updateProcedureInput: {
								id: procedure?.id,
								...(dirtyFields.global && { global }),
								...(dirtyFields.signers && {
									signers: {
										...signers,
										signers: (signers?.signers ?? []).map(({ id: signerId }) => signerId),
									},
								}),
							},
						},
					});
					updatedSigners = updatedProcedure.data?.sign_updateProcedure?.signers ?? [];
				} catch (error) {
					const updateProcedureError = (error as ApolloError).graphQLErrors[0].extensions as UpdateProcedureError;
					if (updateProcedureError.isProcedureNameInvalid) {
						hasInvalidName = true;
						setError('global.name', {
							message: SignMutationErrorMessageEnum[SignMutationErrorEnum.PROCEDURE_NAME_INVALID],
						});
					}
					if (updateProcedureError.isYousignServiceUnavailable) {
						setIsServiceUnavailable(true);
						return {
							id: procedure?.id,
							global,
							signers,
							documents,
							hasErrors: true,
						};
					}
					if (updateProcedureError.partialData?.signers) updatedSigners = updateProcedureError.partialData?.signers;
				}
			}

			// Initialize new procedure.
			const newProcedure: ProcedureInputType & { hasErrors?: boolean } = {
				id: procedure?.id,
				global,
				signers,
				documents,
			};

			if (dirtyFields.signers) {
				// update signer with updated signers.
				newProcedure.signers = {
					isSignOrdered: signers?.isSignOrdered ?? false,
					signers: updatedSigners,
				};

				// Update signer ids in documents Fields.
				newProcedure.documents = updateSignerIdsInDocumentsFields(documents, signers?.signers, updatedSigners);
			}

			if (dirtyFields.documents) {
				const {
					documents: documentsWithYouSignIds,
					signersErrors,
					hasErrors,
				} = await updateDocuments(newProcedure.documents);
				newProcedure.documents = documentsWithYouSignIds;

				// Format signers with errors.
				newProcedure.signers = {
					isSignOrdered: signers?.isSignOrdered ?? false,
					signers: formatSignersWithErrors(
						updatedSigners.length > 0 ? updatedSigners : signers?.signers,
						signersErrors,
					),
				};
				newProcedure.hasErrors = hasErrors || hasInvalidName;
			}

			return newProcedure;
		},
		[
			dirtyFields.documents,
			dirtyFields.global,
			dirtyFields.signers,
			procedure?.id,
			setError,
			updateDocuments,
			updateProcedureMutation,
		],
	);

	const saveProcedure = useCallback(
		async (submittedValues: ProcedureFormType, isTriggeredByStartProcedure = false) => {
			// Reset error
			if (procedure?.hasError)
				await resetProcedureErrorStatus({
					variables: {
						id: procedure.id,
					},
				});

			const { hasErrors, ...savedProcedure } = await (!procedure
				? saveProcedureCreation(submittedValues)
				: saveProcedureUpdate(submittedValues));

			// Set the procedure only if the creation is successfully completed.
			if (savedProcedure.id) {
				setProcedure({
					id: savedProcedure.id,
					...savedProcedure,
				});
			}
			reset(savedProcedure, { keepErrors: true });

			if (!isTriggeredByStartProcedure) {
				if (!hasErrors) showSuccess();
				else showError();
			}

			return { id: savedProcedure.id, hasErrors };
		},
		[procedure, reset, resetProcedureErrorStatus, saveProcedureCreation, saveProcedureUpdate, showError, showSuccess],
	);

	const startProcedure = useCallback(
		async (submittedValues: ProcedureFormType) => {
			setIsTriggeredByStartProcedureButton(true);
			try {
				const { id: savedProcedureId, hasErrors } = await saveProcedure(submittedValues, true);
				if (savedProcedureId && !hasErrors) {
					await startProcedureMutation({
						variables: {
							id: savedProcedureId,
						},
					});

					showSuccess(true);
				} else showError(true);
			} catch {
				setIsServiceUnavailable(true);
				showError(true);
			}
			setIsTriggeredByStartProcedureButton(false);
		},
		[saveProcedure, showError, showSuccess, startProcedureMutation],
	);
	const removeSignature = useCallback(
		(signerId: SignContactType['id'], signerEmail: SignContactType['email']) => {
			setChosenContactsEmails(chosenContactsEmails.filter((email) => email !== signerEmail));
			setValue(
				'documents',
				documentsFormValue?.map(({ fields, ...rest }) => ({
					fields: fields?.filter((field) => field.signerId !== signerId),
					...rest,
				})),
			);
		},
		[chosenContactsEmails, documentsFormValue, setValue],
	);

	const steps = useMemo(
		() => [
			{
				number: 1,
				label: 'Informations',
				isValid: stepValidityMapper.get(0),
				hasError: !!errors.global,
				content: <GlobalForm control={control} procedureId={procedure?.id} onRemoveProcedure={handleClose} />,
			},
			{
				number: 2,
				label: 'Signataires',
				isValid: stepValidityMapper.get(1),
				hasError: signersFormValue?.some(({ hasError }) => !!hasError),
				content: (
					<SignersForm
						control={control}
						onSignerSelection={(signerEmail) => setChosenContactsEmails([...chosenContactsEmails, signerEmail])}
						onSignerRemoval={removeSignature}
						chosenContactsEmails={chosenContactsEmails}
					/>
				),
			},
			{
				number: 3,
				label: 'Documents',
				isValid: stepValidityMapper.get(2),
				hasError: documentsFormValue?.some(({ hasError }) => !!hasError),
				content: <DocumentsForm control={control} signers={signersFormValue ?? []} procedureId={procedure?.id} />,
			},
		],
		[
			chosenContactsEmails,
			control,
			documentsFormValue,
			errors.global,
			handleClose,
			procedure?.id,
			removeSignature,
			signersFormValue,
			stepValidityMapper,
		],
	);
	const handleNext = useCallback(
		() =>
			setCurrentStep((prevCurrentStep) => (prevCurrentStep < steps.length - 1 ? prevCurrentStep + 1 : prevCurrentStep)),
		[steps.length],
	);

	const handlePrevious = useCallback(
		() => setCurrentStep((prevCurrentStep) => (prevCurrentStep > 0 ? prevCurrentStep - 1 : prevCurrentStep)),
		[],
	);

	const extraInfos = useMemo(
		(): DialogProps['extraInfos'] => [
			<Button
				key="backButton"
				onClick={() => {
					if (currentStep === 0) handleClose();
					else handlePrevious();
				}}
			>
				{currentStep === 0 ? 'Annuler' : 'Précedent'}
			</Button>,
		],
		[currentStep, handlePrevious, handleClose],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: !hasSavingSucceeded ? 'Sauvegarder' : 'Procédure sauvegardée',
				onClick: handleSubmit((submittedValues) => saveProcedure(submittedValues)),
				loading:
					!isTriggeredByStartProcedureButton &&
					(isProcedureCreationLoading ||
						isProcedureUpdatingLoading ||
						isUpdatingDocumentsLoading ||
						isUploadingDocumentsLoading),
				success: hasSavingSucceeded,
				error: hasSavingError,
				disabled: !(isDirty && stepValidityMapper.get(0)) || isServiceUnavailable,
			},
			currentStep === steps.length - 1
				? {
						label: hasStartingProcedureSucceeded ? 'Procédure envoyée en signature' : 'Envoyer en signature',
						success: hasStartingProcedureSucceeded,
						error: hasStartingProcedureError,
						disabled: !(isValid && stepValidityMapper.get(2)) || isServiceUnavailable,
						loading:
							isTriggeredByStartProcedureButton &&
							(isProcedureStartingLoading ||
								isProcedureCreationLoading ||
								isProcedureUpdatingLoading ||
								isUpdatingDocumentsLoading ||
								isUploadingDocumentsLoading),
						variant: 'contained',
						onClick: handleSubmit(startProcedure),
				  }
				: {
						label: 'Suivant',
						variant: 'contained',
						onClick: handleNext,
				  },
		],
		[
			hasSavingSucceeded,
			handleSubmit,
			isTriggeredByStartProcedureButton,
			isProcedureCreationLoading,
			isProcedureUpdatingLoading,
			isUpdatingDocumentsLoading,
			isUploadingDocumentsLoading,
			hasSavingError,
			isDirty,
			stepValidityMapper,
			isServiceUnavailable,
			currentStep,
			steps.length,
			hasStartingProcedureSucceeded,
			hasStartingProcedureError,
			isValid,
			isProcedureStartingLoading,
			startProcedure,
			handleNext,
			saveProcedure,
		],
	);

	const dialogStatus = useMemo(
		() =>
			procedure
				? {
						label: 'Brouillon',
						color: 'default',
				  }
				: undefined,
		[procedure],
	);

	return (
		<DialogStepper
			onClose={handleClose}
			dialogTitle={procedure?.global.name ?? "Création d'une procédure"}
			actionsDialog={actionsDialog}
			extraInfos={extraInfos}
			isOpen={isOpen}
			steps={steps}
			dialogStatus={dialogStatus}
			currentStep={currentStep}
			onCurrentStepChange={setCurrentStep}
			commonError={
				isServiceUnavailable
					? `Service temporairement indisponible, la sauvegarde de certains éléments a échoué. Merci de réessayer plus tard.`
					: undefined
			}
			warningMessage={
				procedure?.hasError
					? `Une erreur a été rencontrée lors de l'enregistrement de la procédure, nous vous conseillons de vérifier les informations de la procédure avant d'envoyer celle-ci en signature.`
					: undefined
			}
		/>
	);
};

export default ProcedureDialog;
