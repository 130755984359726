import { gql } from '@apollo/client';

import { FiscalYearType } from '../../types/fiscalYear';

// QUERIES

export type FetchFiscalYearsType = {
	fiscalYears: {
		count: number;
		items: FiscalYearType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_FISCAL_YEARS = gql`
	query FiscalYears($search: String, $page: Page!, $orderBy: OrderBy) {
		fiscalYears(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				code
				id
				name
				startDate
				endDate
			}
			limit
			offset
		}
	}
`;

export type FetchFiscalYearsByCustomerFileType = {
	fiscalYearsByCustomerFile: {
		count: number;
		items: FiscalYearType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_FISCAL_YEARS_BY_CUSTOMER_FILE = gql`
	query FiscalYearsByCustomerFile($customerFileId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		fiscalYearsByCustomerFile(customerFileId: $customerFileId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				code
				id
				name
				startDate
				endDate
			}
			limit
			offset
		}
	}
`;

export type FetchFiscalYearsByUnprocessedMissionsAndCustomerFileType = {
	brochure_fiscalYearsByUnprocessedMissionsAndCustomerFile: {
		count: number;
		items: FiscalYearType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_FISCAL_YEARS_BY_UNPROCESSED_MISSIONS_AND_CUSTOMER_FILE = gql`
	query brochure_fiscalYearsByUnprocessedMissionsAndCustomerFile(
		$customerFileId: ID!
		$search: String
		$page: Page!
		$orderBy: OrderBy
	) {
		brochure_fiscalYearsByUnprocessedMissionsAndCustomerFile(
			customerFileId: $customerFileId
			search: $search
			page: $page
			orderBy: $orderBy
		) {
			count
			items {
				code
				id
				name
				startDate
				endDate
			}
			limit
			offset
		}
	}
`;

export type FetchActiveFiscalYearType = {
	activeFiscalYear: FiscalYearType;
};

export const FETCH_ACTIVE_FISCAL_YEAR = gql`
	query ActiveFiscalYear {
		activeFiscalYear {
			id
		}
	}
`;

export type FetchAllFiscalYearsType = {
	fiscalYearsWithoutPagination: FiscalYearType[];
};

export const FETCH_ALL_FISCAL_YEARS = gql`
	query FiscalYearsWithoutPagination {
		fiscalYearsWithoutPagination {
			endDate
			isClosed
			startDate
		}
	}
`;

export type FetchUsedFiscalYearsType = {
	usedFiscalYears: {
		count: number;
		items: FiscalYearType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_USED_FISCAL_YEARS = gql`
	query UsedFiscalYears($search: String, $page: Page!, $orderBy: OrderBy) {
		usedFiscalYears(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				code
				endDate
				id
				isClosed
				name
				startDate
			}
			limit
			offset
		}
	}
`;
