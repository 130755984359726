import { Icon } from '@elipssolution/harfang';
import { mdiTimerEdit } from '@mdi/js';

import quickentryPages from './pages/pages';
import QuickentrySettings from './settings/QuickentrySettings';
import { ModuleType } from '../../types/module';
import { PermissionEnum } from '../../types/permission';
import { ModuleCodeEnum } from '../../types/widget';

const quickentryModule: ModuleType = {
	code: ModuleCodeEnum.QUICKENTRY,
	icon: <Icon path={mdiTimerEdit} />,
	name: 'Saisie rapide',
	permissionCodes: [
		PermissionEnum.QUICKENTRY_BILL_EXCHANGE_READ,
		PermissionEnum.QUICKENTRY_BILL_EXCHANGE_WRITE,
		PermissionEnum.QUICKENTRY_CHECK_ISSUANCE_READ,
		PermissionEnum.QUICKENTRY_CHECK_ISSUANCE_WRITE,
		PermissionEnum.QUICKENTRY_VOUCHER_READ,
		PermissionEnum.QUICKENTRY_VOUCHER_WRITE,
	],
	route: `/${ModuleCodeEnum.QUICKENTRY}`,
	settings: <QuickentrySettings />,
	subItems: quickentryPages,
};

export default quickentryModule;
