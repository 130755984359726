import type { AppProps } from 'next/app';
import Head from 'next/head';
import { IntlProvider } from 'react-intl';

import AccessGuard from '../components/AccessGuard';
import { ApolloProvider } from '../components/ApolloProvider';
import AppLayout from '../components/AppLayout';
import AuthenticationProvider from '../components/AuthenticationProvider';
import BugSnagProvider from '../components/BugSnagProvider';
import { LogoProvider } from '../components/LogoProvider';
import { SessionProvider } from '../components/SessionProvider';

import '@elipssolution/harfang/dist/index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App = ({ Component, pageProps, router }: AppProps) => {
	const { pathname } = router;

	const render = () => {
		if (pathname.startsWith('/error') || pathname.startsWith('/auth')) {
			return <Component {...pageProps} />;
		}

		return (
			<AccessGuard>
				<BugSnagProvider>
					<AppLayout>
						<Component {...pageProps} />
					</AppLayout>
				</BugSnagProvider>
			</AccessGuard>
		);
	};

	// FIXME: Remonter le ThemeProvider ici
	return (
		<>
			<Head>
				<title>Espace client Suite</title>
			</Head>

			<AuthenticationProvider>
				<ApolloProvider>
					<SessionProvider>
						<LogoProvider>
							<IntlProvider locale="fr">{render()}</IntlProvider>
						</LogoProvider>
					</SessionProvider>
				</ApolloProvider>
			</AuthenticationProvider>
		</>
	);
};

export default App;
