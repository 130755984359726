import { SessionProvider as NextAuthSessionProvider, signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';

import SplashScreen from './SplashScreen';
import useCustomRouter from '../hooks/useCustomRouter';

const Authentication = ({ children }: { children: JSX.Element }) => {
	const { pathname } = useCustomRouter();

	const isIgnoredPage = pathname.startsWith('/auth');

	const { status, data: session } = useSession({
		required: true,
		onUnauthenticated: () => {
			if (isIgnoredPage) return;

			signIn('keycloak', { callbackUrl: '/' }).catch((error) => {
				throw error;
			});
		},
	});

	useEffect(() => {
		if (session?.error === 'RefreshAccessTokenError') {
			signIn('keycloak', { callbackUrl: '/' }).catch((e) => {
				throw e;
			}); // Force sign in to hopefully resolve error
		}
	}, [session]);

	if (!isIgnoredPage && status === 'loading') return <SplashScreen description="Chargement de l'utilisateur..." />;

	return children;
};

const AuthenticationProvider = ({ children }: { children: JSX.Element }) => {
	return (
		<NextAuthSessionProvider refetchInterval={5} refetchOnWindowFocus>
			<Authentication>{children}</Authentication>
		</NextAuthSessionProvider>
	);
};

export default AuthenticationProvider;
