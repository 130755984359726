import { useMutation } from '@apollo/client';
import { Button, ButtonSwitch, ConfirmationDialog, DialogProps, TextField } from '@elipssolution/harfang';
import { Stack, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { REMOVE_PROCEDURE, RemoveProcedureType } from '../../../api/procedure';
import {
	SignMutationErrorEnum,
	ProcedureFormType,
	ProcedureType,
	SignMutationErrorMessageEnum,
} from '../../../types/procedure';
import { VisibilityLabelsTypeEnum, VisibilityTypeEnum } from '../../../types/visibility';

const FormWrapper = styled(Stack)(({ theme: { spacing } }) => ({
	alignItems: 'flex-start',
	gap: spacing(2),
}));

const buttonSwitchItems = Object.values(VisibilityTypeEnum).map((id) => ({ id, label: VisibilityLabelsTypeEnum[id] }));

type GlobalFormProps = {
	control: Control<ProcedureFormType>;
	procedureId?: ProcedureType['id'];
	onRemoveProcedure?: () => void;
};

const GlobalForm = ({ control, procedureId, onRemoveProcedure }: GlobalFormProps) => {
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [deleteProcedureError, setDeleteProcedureError] = useState<string>();
	const [removeProcedure] = useMutation<RemoveProcedureType>(REMOVE_PROCEDURE, {
		onCompleted: () => onRemoveProcedure?.(),
		onError: () => setDeleteProcedureError(SignMutationErrorMessageEnum[SignMutationErrorEnum.YOUSIGN_UNAVAILABLE]),
	});

	const handleRemoveProcedure = useCallback(
		async ({ id }: { id: ProcedureType['id'] }) => {
			await removeProcedure({
				variables: {
					signRemoveProcedureId: id,
				},
			});
		},
		[removeProcedure],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Annuler',
				disabled: !!deleteProcedureError,
				onClick: () => setIsConfirmationDialogOpen(false),
			},
			{
				color: 'error',
				label: 'Supprimer',
				error: !!deleteProcedureError,
				onClick: async () => {
					if (procedureId) await handleRemoveProcedure({ id: procedureId });
				},
				persistantErrorMessage: deleteProcedureError,
				disabled: !!deleteProcedureError,
				variant: 'contained',
			},
		],
		[deleteProcedureError, handleRemoveProcedure, procedureId],
	);
	return (
		<FormWrapper>
			<Controller
				name="global.isPrivate"
				control={control}
				defaultValue={VisibilityTypeEnum.PRIVATE === buttonSwitchItems[0].id}
				render={({ field: { value, onChange } }) => (
					<ButtonSwitch
						selectedItem={value ? VisibilityTypeEnum.PRIVATE : VisibilityTypeEnum.PUBLIC}
						items={buttonSwitchItems}
						onClick={(id) => onChange(id === VisibilityTypeEnum.PRIVATE)}
					/>
				)}
			/>

			<Controller
				rules={{ required: true }}
				name="global.name"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<TextField
						sx={{ width: '55%' }}
						{...field}
						invalid={!!error}
						helperText={error?.message || ' '}
						label="Nom de la procédure"
						required
					/>
				)}
			/>

			<Controller
				name="global.comment"
				control={control}
				render={({ field }) => <TextField sx={{ width: '75%' }} {...field} rows={4} multiline label="Commentaire" />}
			/>
			{procedureId && (
				<>
					<Button color="error" variant="outlined" onClick={() => setIsConfirmationDialogOpen(true)}>
						Supprimer la procédure
					</Button>
					<ConfirmationDialog
						actionsDialog={actionsDialog}
						open={isConfirmationDialogOpen}
						onClose={() => {
							setIsConfirmationDialogOpen(false);
							setDeleteProcedureError(undefined);
						}}
						title="Êtes-vous sûr de vouloir supprimer la procédure?"
					/>
				</>
			)}
		</FormWrapper>
	);
};

export default GlobalForm;
