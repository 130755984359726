import { Icon, Tooltip } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiDragVertical } from '@mdi/js';
import { alpha, styled } from '@mui/material';
import clsx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';

import ContactItem from './ContactItem';
import { SignContactType } from '../../../types/contact';
import { SignerWithErrorType } from '../../../types/procedure';

const StyledRow = styled('div')(({ theme: { palette } }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	borderBottom: `1px solid ${palette.divider}`,
	cursor: 'pointer',
	'&:last-of-type': {
		borderBottom: 'none',
	},
	'&:hover': {
		backgroundColor: alpha(palette.text.primary, palette.action.hoverOpacity),
		// Reset on touch devices, it doesn't add specificity
		'@media (hover: none)': {
			backgroundColor: 'transparent',
		},
	},
	'&.hasError': { backgroundColor: `${alpha(palette.error.main, 0.05)}` },
}));
const StyledSignerItem = styled('div')(({ theme: { spacing } }) => ({
	gap: spacing(1),
	display: 'flex',
}));

const StyledDragIcon = styled('div')(({ theme: { spacing } }) => ({
	paddingLeft: spacing(0.5),
	display: 'flex',
	alignItems: 'center',
	cursor: 'move',
}));
const StyledIconContainer = styled('div')(({ theme: { spacing } }) => ({
	padding: spacing(2),
	display: 'flex',
	alignItems: 'center',
}));

type SelectedSignerItemProps = {
	signer: SignerWithErrorType;
	moveContact: (dragIndex: SignContactType['id'], hoverIndex: number) => void;
	isOrderingEnabled: boolean;
	findContactIndex: (id: SignContactType['id']) => number;
	onRowClick?: (contactId: SignContactType['id'], email: SignContactType['email']) => void;
};
type Item = {
	id: SignContactType['id'];
	originalIndex: number;
};
export const ItemTypes = {
	CONTACT: 'contact',
};

const SelectedSignerItem = ({
	signer: { firstName, lastName, email, id: signerId, hasError, message },
	moveContact,
	isOrderingEnabled,
	findContactIndex,
	onRowClick,
}: SelectedSignerItemProps) => {
	const originalIndex = findContactIndex(signerId);
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: ItemTypes.CONTACT,
			item: { id: signerId, originalIndex },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				const { id: droppedId, originalIndex: itemIndex } = item;

				if (!monitor.didDrop()) {
					moveContact(droppedId, itemIndex);
				}
			},
		}),
		[signerId, originalIndex, moveContact],
	);

	const [, drop] = useDrop(
		() => ({
			accept: ItemTypes.CONTACT,
			hover({ id: draggedId }: Item) {
				if (draggedId !== signerId) {
					const overIndex = findContactIndex(signerId);
					moveContact(draggedId, overIndex);
				}
			},
		}),
		[findContactIndex, moveContact],
	);

	return (
		<StyledRow
			ref={isOrderingEnabled ? (node) => drag(drop(node)) : null}
			style={{ opacity: isDragging ? 0 : 1 }}
			onClick={() => onRowClick?.(signerId, email)}
			className={clsx({ hasError })}
		>
			<StyledSignerItem>
				{isOrderingEnabled && (
					<StyledDragIcon ref={drag}>
						<Icon path={mdiDragVertical} size="small" />
					</StyledDragIcon>
				)}
				<ContactItem firstName={firstName} lastName={lastName} email={email} />
			</StyledSignerItem>

			<Tooltip content={message}>
				<StyledIconContainer>
					{hasError && <Icon path={mdiAlertCircle} color="error" size="small" />}
				</StyledIconContainer>
			</Tooltip>
		</StyledRow>
	);
};

export default SelectedSignerItem;
