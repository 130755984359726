import { Stack } from '@mui/material';

import DeclinedProceduresTable from './DeclinedProceduresTable';
import ProceduresTableHeader from '../../../components/ProceduresTableHeader';

const Declined = () => (
	<Stack height="100%" gap={2}>
		<ProceduresTableHeader />

		<DeclinedProceduresTable />
	</Stack>
);

export default Declined;
