import { Icon } from '@elipssolution/harfang';
import { mdiDrawPen } from '@mdi/js';

import signPages from './pages/pages';
import { PermissionEnum } from '../../types/permission';
import { ModuleCodeEnum } from '../../types/widget';

const signModule = {
	code: ModuleCodeEnum.SIGN,
	icon: <Icon path={mdiDrawPen} />,
	name: 'Signature',
	route: `/${ModuleCodeEnum.SIGN}`,
	subItems: signPages,
	permissionCodes: [PermissionEnum.SIGN_ACCESS],
};

export default signModule;
