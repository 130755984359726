import accountingModule from './accounting/module';
import brochureModule from './brochure/module';
import documentModule from './document/module';
import quickentryModule from './quickentry/module';
import signModule from './sign/module';
import { ModuleType } from '../types/module';

const modules: ModuleType[] = [documentModule, quickentryModule, accountingModule, signModule, brochureModule];

export default modules;
